import React, { Component, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, LockClosedIcon, SelectorIcon } from '@heroicons/react/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class SingleSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: {},
            locked: false
        }
    };

    componentDidMount() {
        this.setState({
            options: this.props.options ? this.props.options : [],
            selected: this.props.selected ? this.props.selected : {},
            disabled: this.props.disabled,
            locked: this.props.locked
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            options: nextProps.options ? nextProps.options : [],
            selected: nextProps.selected ? nextProps.selected : {},
            disabled: nextProps.disabled,
            locked: nextProps.locked
        })
    }

    render() {
        return (
            <>
                <Listbox disabled={this.state.disabled} value={this.state.selected} onChange={(value) => {
                    this.props.select(value);
                }}>
                    {({ open }) => (
                        <>
                            <div className="mt-1 relative">
                                <Listbox.Button className={classNames(
                                    this.state.disabled ? 'bg-gray-100' : 'bg-white',
                                    'relative w-full border cursor-pointer border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none sm:text-sm'
                                )}>
                                    <div className="flex items-center truncate">
                                        {
                                            this.state.selected.image &&
                                            <img src={this.state.selected.image} alt="" className="flex-shrink-0 h-5 w-5 rounded-full mr-3" />
                                        }
                                        {
                                            this.state.selected.channel && (this.state.selected.channel.value === 'facebook' || this.state.selected.channel.value === 'meta') &&
                                            <div className="flex-shrink-0 bg-facebook-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_meta.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'google' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_google.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'tiktok' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_tiktok.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'snapchat' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_snapchat.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && (this.state.selected.channel.value === '' || this.state.selected.channel.value === 'internal' || !this.state.selected.channel.value) &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_internal.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'proptree' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_proptree.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        <span className="block truncate">
                                            {this.state.selected.name}
                                        </span>
                                        {
                                            this.props.showTag && this.state.selected.tag &&
                                            <span className="text-xxs p-1 ml-2 bg-purple-100 rounded-md text-purple-500">{this.state.selected.tag}</span>
                                        }
                                        {
                                            this.state.selected.version &&
                                            <span className={'block truncate text-indigo-500 ml-1'}>
                                                {this.state.selected.version.id == 1 ? "LIVE" : "DEV"}{" •"}{this.state.selected.legacy && this.state.selected.legacy.value === "enabled" ? this.state.selected.parserType && this.state.selected.parserType.value !== "" ? this.state.selected.parserType.value.indexOf("project") !== -1 ? (<span className="text-sm text-pink-500"> LIGHT • PROJECT</span>) : (<span className="text-sm text-pink-500"> LIGHT • UNIT</span>) : (<span className="text-sm text-pink-500"> LIGHT</span>) : (<span className="text-sm text-indigo-500"> LEGACY</span>)}
                                            </span>
                                        }
                                    </div>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        {
                                            !this.props.icon && !this.state.locked &&
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        }
                                        {
                                            !this.props.icon && this.state.locked &&
                                            <LockClosedIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
                                        }
                                        {
                                            this.props.icon &&
                                            <this.props.icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        }
                                    </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white border shadow-lg max-h-40 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-y-scroll focus:outline-none sm:text-sm">
                                        {this.state.options.map((option) => (
                                            <Listbox.Option
                                                key={option.id}
                                                className={({ active }) =>
                                                    classNames(
                                                        'cursor-pointer hover:text-gray hover:bg-indigo-100 text-gray-900 select-none relative py-2 pl-3 pr-9'
                                                    )}
                                                value={option}
                                            >
                                                <>
                                                    <div className="flex items-center">
                                                        {
                                                            option.image &&
                                                            <img src={option.image} alt="" className="flex-shrink-0 h-5 w-5 rounded-full mr-3" />
                                                        }
                                                        {
                                                            option.channel && (option.channel.value === 'facebook' || option.channel.value === 'meta') &&
                                                            <div className="flex-shrink-0 bg-facebook-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_meta.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'google' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_google.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'tiktok' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_tiktok.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'snapchat' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_snapchat.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && (option.channel.value === '' || option.channel.value === 'internal' || !option.channel.value) &&
                                                            <div className="flex-shrink-0 bg-facebook-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_internal.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'proptree' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_proptree.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        <span className={classNames(this.state.selected.id == option.id ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                            {option.name}
                                                        </span>
                                                        {
                                                            this.props.showTag && option.tag &&
                                                            <span className="text-xxs p-1 ml-2 bg-purple-100 rounded-md text-purple-500">{option.tag}</span>
                                                        }
                                                        {
                                                            option.version &&
                                                            <span className={'block truncate text-indigo-500 ml-1'}>
                                                                {option.version.id == 1 ? "LIVE" : "DEV"}{" •"}{option.legacy && option.legacy.value === "enabled" ? option.parserType && option.parserType.value !== "" ? option.parserType.value.indexOf("project") !== -1 ? (<span className="text-sm text-pink-500"> LIGHT • PROJECT</span>) : (<span className="text-sm text-pink-500"> LIGHT • UNIT</span>) : (<span className="text-sm text-pink-500"> LIGHT</span>) : (<span className="text-sm text-indigo-500"> LEGACY</span>)}
                                                            </span>
                                                        }
                                                    </div>
                                                    {this.state.selected.id && this.state.selected.id === option.id ? (
                                                        <span
                                                            className={classNames(
                                                                ' text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            </>
        )
    }
}

export default SingleSelection;
