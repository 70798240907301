import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, PlusIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';

class CreateNewProptree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listings: [],
            user_enterprise: {},
            enterprises: [],
            forms: [],
            feed: {},
            version: {},
            access: {},
            adbooster_type: { id: 1, name: "Property Booster" },
            dontShowSubmitButton: false,
            preview: null,
            proptree: {},

            page_listings: 1,
            total_listings: 0,
            sale_listings: 0,
            rent_listings: 0,
            loading_listings: false
        }
    };

    componentDidMount() {

    }

    async componentWillReceiveProps(nextProps) {
        let user = userRegister.get();
        await this.promisedSetState({ user: user });
        if (!this.state.open && nextProps.open) {
            this.functions.getFeeds();
            this.state.proptree = (nextProps.proptree ? nextProps.proptree : { profileCompany: nextProps.enterprise.companyName, listings: {} });
            this.state.preview = true;
        }
        await this.promisedSetState({
            proptree: this.state.proptree,
            open: nextProps.open,
            user_enterprise: nextProps.enterprise,
            dontShowSubmitButton: nextProps.dontShowSubmitButton
        });
        if (this.state.proptree.id) {
            this.functions.getListings();
        }
        await this.promisedSetState({
            preview: this.state.preview
        });
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
        },
        getFeeds: async () => {
            try {
                await this.promisedSetState({ loading_feeds: true });
                let response = await this.calls.getFeeds();
                this.setState({
                    feeds: response.data,
                    total: response.meta ? response.meta.total : 0,
                    loading_feeds: false
                })
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_feeds: false
                })
            }
        },
        createProptree: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let proptree = JSON.parse(JSON.stringify(this.state.proptree));
                await this.calls.createProptree(proptree);
                await this.promisedSetState({ loading: false });
                if (this.props.onCreate) {
                    this.props.onCreate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        updateProptree: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let proptree = JSON.parse(JSON.stringify(this.state.proptree));
                await this.calls.updateProptree(proptree);
                await this.promisedSetState({ loading: false });
                if (this.props.onUpdate) {
                    this.props.onUpdate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        uploadImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadImage(formData).then((response) => {
                        this.state.proptree.profileImage = response.data;
                        this.setState({
                            proptree: this.state.proptree,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        },
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_listings: true });
                let response = await this.calls.getListings();
                await this.promisedSetState({
                    listings: response.data,
                    total_listings: response.meta[(this.state.proptree.propertiesType ? this.state.proptree.propertiesType.value : "both")] ? response.meta[(this.state.proptree.propertiesType ? this.state.proptree.propertiesType.value : "both")] : 0,
                    sale_listings: response.meta.sale ? response.meta.sale : 0,
                    rent_listings: response.meta.rent ? response.meta.rent : 0,
                    loading_listings: false
                });
            } catch (error) {
                this.setState({
                    error_listings: true,
                    message_listings: error.body ? error.body.message : "Something went wrong",
                    loading_listings: false
                })
            }
        }
    };

    renders = {
        valid: () => {
            let valid_answers = [];
            let mandatory = ["profileImage", "profileFirstName", "profileCompany", "whatsAppNumber", "whatsAppCountryCode", "propertiesType", "property"];
            mandatory.map((item) => {
                if (item == "whatsAppCountryCode") {
                    if (this.state.proptree.whatsAppCountryCode && this.state.proptree.whatsAppCountryCode.value) {
                        valid_answers.push(item);
                    }
                } else if (item == "propertiesType") {
                    if (this.state.proptree.propertiesType && this.state.proptree.propertiesType.value) {
                        valid_answers.push(item);
                    }
                } else if (item == "property") {
                    if (this.state.proptree.property && this.state.proptree.property.value) {
                        valid_answers.push(item);
                    }
                } else if (typeof this.state.proptree[item] == "string" && this.state.proptree[item] !== "") {
                    valid_answers.push(item);
                }
            });
            if (valid_answers.length == mandatory.length) {
                if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                    return this.state.proptree.listings && Object.keys(this.state.proptree.listings).length > 0
                } else {
                    return valid_answers.length == mandatory.length;
                }
            }
        },
        previewUrl: () => {
            let string = "https://app.adbooster.ai/123/proptree?preview=true";
            if (this.state.user_enterprise && this.state.user_enterprise.id) {
                string += "&enterprise=" + this.state.user_enterprise.id;
            }
            if (this.state.proptree.profileFirstName) {
                string += "&profileFirstName=" + this.state.proptree.profileFirstName;
            }
            if (this.state.proptree.profileImage) {
                string += "&profileImage=" + this.state.proptree.profileImage;
            }
            if (this.state.proptree.profileCompany) {
                string += "&profileCompany=" + this.state.proptree.profileCompany;
            }
            if (this.state.proptree.propertiesType && this.state.proptree.propertiesType.value) {
                string += "&propertiesType=" + this.state.proptree.propertiesType.value;
            }
            if (this.state.proptree.property && this.state.proptree.property.value) {
                string += "&property=" + this.state.proptree.property.value;
            }
            if (this.state.proptree.propertiesType && this.state.proptree.propertiesType.value && this.state.proptree.property && this.state.proptree.property.value) {
                if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                    if (this.state.proptree.listings && Object.keys(this.state.proptree.listings).length > 0) {
                        string += "&showListings=true";
                        string += "&listings=" + (this.state.proptree.listings ? Object.keys(this.state.proptree.listings) : "");
                    }
                } else {
                    string += "&showListings=true";
                }
            }
            if (this.state.proptree.showButtons) {
                string += "&showButtons=true";
                if (Array.isArray(this.state.proptree.buttons) && this.state.proptree.buttons.length > 0) {
                    this.state.proptree.buttons.map((button, index) => {
                        string += "&buttons[" + index + "][text]=" + button.text + "&buttons[" + index + "][url]=" + button.url;
                    });
                }
            }
            console.log(string);
            return string;
        }
    };

    calls = {
        getFeeds: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed-light?type=proptree";
            return apiRegister.call(options, url);
        },
        updateProptree: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/proptree/updateProptree/" + this.state.proptree.id;
            return apiRegister.call(options, url);
        },
        createProptree: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/createProptree";
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image";
            return apiRegister.call(options, url);
        },
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings/getListingsShortInfo?page=" + this.state.page_listings + "&limit=100000&orderBy=ascending&sortBy=reference&type=" + (this.state.proptree.propertiesType ? this.state.proptree.propertiesType.value : "both") + "&status=ready" + (this.state.proptree.property && this.state.proptree.property.value ? ("&property=" + this.state.proptree.property.value) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-7xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-row bg-white shadow-xl">

                                        <div className="md:flex hidden flex-1 flex-col">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">Preview</Dialog.Title>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center mt-12 px-4 sm:px-6">
                                                <div className="bg-gray-100 relative border overflow-hidden" style={{ borderRadius: '30px', width: "350px", height: "600px" }}>
                                                    {
                                                        this.state.preview &&
                                                        <iframe
                                                            id="proptree_preview"
                                                            src={this.renders.previewUrl()}
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                border: "none",
                                                                //objectFit: "contain", /* creates issue with safari */
                                                            }}
                                                            frameBorder="0"
                                                            //scrolling="no"
                                                            allowFullScreen
                                                            aria-hidden="false"
                                                            tabIndex="0"
                                                        ></iframe>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="max-w-4xl w-full h-full flex flex-col border-l">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.proptree.id ? "Update Proptree" : "New Proptree"}</Dialog.Title>
                                                    <div className="ml-3 h-10 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 h-0 overflow-y-auto">
                                                <div className="flex flex-row">
                                                    <div className="flex flex-1 flex-col pt-6">
                                                        <div className="px-4 sm:grid-cols-12 grid-cols-4 grid gap-5 sm:px-6">
                                                            <div className="col-span-4">
                                                                <div className="mt-1 flex items-center">
                                                                    {
                                                                        !this.state.proptree.profileImage &&
                                                                        <span className="inline-block h-20 w-20 rounded-full relative overflow-hidden bg-gray-100">
                                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                            </svg>
                                                                            {
                                                                                this.state.loading_image &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </span>
                                                                    }
                                                                    {
                                                                        this.state.proptree.profileImage &&
                                                                        <div className="rounded-full relative mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                                                            <div>
                                                                                <img
                                                                                    className="w-full"
                                                                                    src={this.state.proptree.profileImage}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            {
                                                                                this.state.loading_image &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className="ml-4 flex">
                                                                        <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                                            <label
                                                                                htmlFor="user-photo"
                                                                                className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                                                            >
                                                                                {
                                                                                    this.state.proptree.profileImage &&
                                                                                    <span>Change image</span>
                                                                                }
                                                                                {
                                                                                    !this.state.proptree.profileImage &&
                                                                                    <span>Upload image</span>
                                                                                }
                                                                                <span className="sr-only"> user photo</span>
                                                                            </label>
                                                                            {
                                                                                <input
                                                                                    ref="uploadImageFile"
                                                                                    onClick={() => this.refs.uploadImageFile.value = null}
                                                                                    onChange={(e) => this.functions.uploadImage(e)}
                                                                                    type="file"
                                                                                    accept={"image/png,image/jpg,image/jpeg"}
                                                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-8 divide-y divide-gray-200">
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="proptree-shortname" className="block text-sm font-medium text-gray-900">
                                                                            Custom shortname (optional)
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <div className="bg-gray-50 border text-sm border-r-none rounded-l-md shadow-sm items-center inline-flex h-10 px-4">
                                                                                https://proptr.ee/
                                                                            </div>
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.alias = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.alias ? this.state.proptree.alias : ""}
                                                                                type="text"
                                                                                name="proptree-shortname"
                                                                                id="proptree-shortname"
                                                                                className={"appearance-none block rounded-l-none w-full px-3 sm:py-2 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 mt-4 grid-cols-6 grid gap-5 sm:px-6">
                                                            <div className="sm:col-span-3 col-span-6 divide-y divide-gray-200">
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="proptree-name" className="block text-sm font-medium text-gray-900">
                                                                            Tagline
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.profileFirstName = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.profileFirstName ? this.state.proptree.profileFirstName : ""}
                                                                                type="text"
                                                                                name="proptree-name"
                                                                                id="proptree-name"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="sm:col-span-3 col-span-6 divide-y divide-gray-200">
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="proptree-company" className="block text-sm font-medium text-gray-900">
                                                                            Company
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.profileCompany = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.profileCompany ? this.state.proptree.profileCompany : ""}
                                                                                disabled={true}
                                                                                type="text"
                                                                                name="proptree-company"
                                                                                id="proptree-company"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 relative grid-cols-12 grid gap-5 -mt-5 mb-5">
                                                                <div className="sm:col-span-3 col-span-12">
                                                                    <label htmlFor="proptree-dial-code" className="block text-sm font-medium text-gray-900">
                                                                        Country code
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={async (option) => {
                                                                                this.state.proptree.whatsAppCountryCode = option;
                                                                                await this.promisedSetState({
                                                                                    proptree: this.state.proptree
                                                                                });
                                                                            }}
                                                                            disabled={this.state.proptree.id}
                                                                            selected={this.state.proptree.whatsAppCountryCode ? this.state.proptree.whatsAppCountryCode : { id: 0, name: 'Click to Select' }}
                                                                            options={[
                                                                                { id: 1, name: 'UAE (+971)', value: "971" },
                                                                                { id: 3, name: 'Sweden (+46)', value: "46" },
                                                                                { id: 2, name: 'UK (+44)', value: "44" }
                                                                            ]}
                                                                            name="proptree-dial-code"
                                                                            id="proptree-dial-code"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="sm:col-span-9 col-span-12">
                                                                    <label htmlFor="proptree-whatsapp" className="block text-sm font-medium text-gray-900">
                                                                        WhatsApp
                                                                    </label>
                                                                    <div className="mt-1 flex">
                                                                        <input
                                                                            onChange={(event) => {
                                                                                if (!this.state.proptree.id) {
                                                                                    this.state.proptree.whatsAppNumber = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }
                                                                            }}
                                                                            readOnly={this.state.proptree.id}
                                                                            disabled={this.state.proptree.id}
                                                                            value={this.state.proptree.whatsAppNumber ? this.state.proptree.whatsAppNumber : ""}
                                                                            type="text"
                                                                            name="proptree-whatsapp"
                                                                            id="proptree-whatsapp"
                                                                            className={(this.state.proptree.id ? "bg-gray-100" : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 pb-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="w-full col-span-12 border-b">
                                                                <div className="text-sm font-bold pb-1 text-gray-900">Banner</div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-3 relative">
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="proptree-banner" className="block text-sm font-medium text-gray-900">
                                                                            Show banner
                                                                        </label>
                                                                        <div className="mt-1 relative">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.proptree.showBanner = option;
                                                                                    await this.promisedSetState({
                                                                                        proptree: this.state.proptree,
                                                                                        select_all: false
                                                                                    });
                                                                                }}
                                                                                //disabled={this.state.proptree.id}
                                                                                selected={this.state.proptree.showBanner ? this.state.proptree.showBanner : { id: 1, name: 'Disabled', value: "disabled" }}
                                                                                options={[
                                                                                    { id: 1, name: 'Disabled', value: 'disabled' },
                                                                                    { id: 2, name: 'Enabled', value: 'enabled' }
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.proptree.showBanner && this.state.proptree.showBanner.value == "enabled" &&
                                                                <div className="col-span-12 sm:col-span-3 relative">
                                                                    <div className="space-y-6 pb-5 sm:mt-0 -mt-5">
                                                                        <div>
                                                                            <label htmlFor="proptree-banner-type" className="block text-sm font-medium text-gray-900">
                                                                                Banner type
                                                                            </label>
                                                                            <div className="mt-1 relative">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.bannerType = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                    }}
                                                                                    selected={this.state.proptree.bannerType ? this.state.proptree.bannerType : { id: 1, name: 'Fallback', value: "default" }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Fallback', value: "default" },
                                                                                        { id: 2, name: 'Feed', value: "other_listings" }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="px-4 pb-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="w-full col-span-12 border-b">
                                                                <div className="text-sm font-bold pb-1 text-gray-900">Filters</div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-3 relative">
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="proptree-feed" className="block text-sm font-medium text-gray-900">
                                                                            Form
                                                                        </label>
                                                                        <div className="mt-1 relative">
                                                                            {
                                                                                this.state.loading_listings &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.proptree.feed = option;
                                                                                    await this.promisedSetState({
                                                                                        proptree: this.state.proptree
                                                                                    });
                                                                                }}
                                                                                disabled={this.state.proptree.id}
                                                                                selected={this.state.proptree.feed ? this.state.proptree.feed : { id: 0, name: 'Click to Select' }}
                                                                                options={this.state.feeds}
                                                                                name="proptree-feed"
                                                                                id="proptree-feed"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-3 relative">
                                                                {
                                                                    !(this.state.proptree.feed && this.state.proptree.feed.id) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="proptree-feed-type" className="block text-sm font-medium text-gray-900">
                                                                            Type
                                                                        </label>
                                                                        <div className="mt-1 relative">
                                                                            {
                                                                                this.state.loading_listings &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.propertiesType = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.propertiesType ? this.state.proptree.propertiesType : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Sale & Rentals', value: "both" },
                                                                                        { id: 2, name: 'Sale', value: "sale" },
                                                                                        { id: 3, name: 'Rentals', value: "rent" }
                                                                                    ]}
                                                                                    name="proptree-feed-type"
                                                                                    id="proptree-feed-type"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.propertiesType = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.propertiesType ? this.state.proptree.propertiesType : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 2, name: 'Sale', value: "sale" }
                                                                                    ]}
                                                                                    name="proptree-feed-type"
                                                                                    id="proptree-feed-type"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-3 relative">
                                                                {
                                                                    !(this.state.proptree.propertiesType && this.state.proptree.propertiesType.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="proptree-feed-plan" className="block text-sm font-medium text-gray-900">
                                                                            Plan
                                                                        </label>
                                                                        <div className="mt-1 relative">
                                                                            {
                                                                                this.state.loading_listings &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.property = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.property ? this.state.proptree.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'On-plan & Off-plan', value: "both" },
                                                                                        { id: 2, name: 'On-plan', value: 'on_plan' },
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ]}
                                                                                    name="proptree-feed-plan"
                                                                                    id="proptree-feed-plan"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.property = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.property ? this.state.proptree.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ]}
                                                                                    name="proptree-feed-plan"
                                                                                    id="proptree-feed-plan"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-3 relative">
                                                                {
                                                                    !(this.state.proptree.property && this.state.proptree.property.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="proptree-feed-filter" className="block text-sm font-medium text-gray-900">
                                                                            Custom
                                                                        </label>
                                                                        <div className="mt-1 relative">
                                                                            {
                                                                                this.state.loading_listings &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.proptree.chooseListings = option;
                                                                                    this.state.proptree.listings = {};
                                                                                    await this.promisedSetState({
                                                                                        proptree: this.state.proptree,
                                                                                        select_all: false
                                                                                    });
                                                                                    if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                        this.functions.getListings();
                                                                                    }
                                                                                }}
                                                                                //disabled={this.state.proptree.id}
                                                                                selected={this.state.proptree.chooseListings ? this.state.proptree.chooseListings : { id: 1, name: 'Disabled', value: "disabled" }}
                                                                                options={[
                                                                                    { id: 1, name: 'Disabled', value: 'disabled' },
                                                                                    { id: 2, name: 'Enabled', value: 'enabled' }
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") &&
                                                            !this.state.loading_listings &&
                                                            this.state.listings.length > 0 &&
                                                            <div className="px-4 w-full sm:px-6">
                                                                <div className="w-full border-b">
                                                                    <div className="text-sm font-bold pb-1 text-gray-900">Select listings</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") &&
                                                            !this.state.loading_listings &&
                                                            this.state.listings.length > 0 &&
                                                            <div className="w-full sm:px-6 px-4 mt-4 flex items-center">
                                                                <div className="">
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4 text-sm font-medium">Select all ({this.state.total_listings})</div>
                                                                        <div onClick={async () => {
                                                                            await this.promisedSetState({
                                                                                proptree: this.state.proptree,
                                                                                select_all: !this.state.select_all
                                                                            });
                                                                            if (!this.state.select_all) {
                                                                                this.state.proptree.listings = {};
                                                                            } else {
                                                                                this.state.proptree.listings = {};
                                                                                this.state.listings.map((item) => {
                                                                                    this.state.proptree.listings[item.externalId] = true;
                                                                                });
                                                                            }
                                                                            await this.promisedSetState({
                                                                                proptree: this.state.proptree
                                                                            });
                                                                        }} style={{ height: "39px", width: "39px" }} className={(this.state.select_all ? "bg-purple-500" : "") + " border cursor-pointer flex items-center justify-center rounded-md bg-gray-50"}>
                                                                            {this.state.select_all && <CheckIcon className="w-5 text-white"></CheckIcon>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-1 ml-2">
                                                                    <div className="w-full">
                                                                        <input
                                                                            onChange={async (event) => {
                                                                                await this.promisedSetState({
                                                                                    search_listings: event.target.value
                                                                                })
                                                                            }}
                                                                            placeholder={"Search listings"}
                                                                            value={this.state.search_listings}
                                                                            type="text"
                                                                            name="proptree-feed-search"
                                                                            id="proptree-feed-search"
                                                                            className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") &&
                                                            !this.state.loading_listings &&
                                                            this.state.listings.length > 0 &&
                                                            <div className="w-full sm:px-6 px-4 mt-4">
                                                                <div className="flex flex-col border rounded-md p-2 pt-0 bg-gray-50">
                                                                    {this.state.listings.map((item) => {
                                                                        return (
                                                                            <div>
                                                                                <div onClick={() => {
                                                                                    if (!this.state.proptree.listings) {
                                                                                        this.state.proptree.listings = {};
                                                                                    }
                                                                                    if (this.state.proptree.listings[item.externalId]) {
                                                                                        delete this.state.proptree.listings[item.externalId];
                                                                                    } else {
                                                                                        this.state.proptree.listings[item.externalId] = true;
                                                                                    }
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    });
                                                                                }} className="w-full mt-2 flex flex-row items-center mr-4">
                                                                                    <div style={{ height: "39px", width: "39px" }} className={(this.state.proptree.listings && this.state.proptree.listings[item.externalId] ? "bg-purple-500" : "") + " border cursor-pointer flex items-center justify-center rounded-md bg-white"}>
                                                                                        {this.state.proptree.listings && this.state.proptree.listings[item.externalId] && <CheckIcon className="w-5 text-white"></CheckIcon>}
                                                                                    </div>
                                                                                    <div className="ml-4 text-sm font-medium">{item.externalId}</div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.state.error &&
                                                    <div className="px-6 pb-6">
                                                        <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                                                            <li>{this.state.error}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex-shrink-0 px-6 py-4 flex justify-end">
                                                {
                                                    !this.state.dontShowSubmitButton &&
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.proptree.id) {
                                                                if (this.renders.valid()) {
                                                                    this.functions.updateProptree();
                                                                }
                                                            } else {
                                                                if (this.renders.valid()) {
                                                                    this.functions.createProptree();
                                                                }
                                                            }
                                                        }}
                                                        className={(this.renders.valid() ? "bg-purple-500 hover:bg-purple-600 cursor-pointer" : "bg-gray-200 cursor-not-allowed") + " ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                    >
                                                        {
                                                            this.state.loading &&
                                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.proptree.id &&
                                                            <span>Create</span>
                                                        }
                                                        {
                                                            this.state.proptree.id &&
                                                            <span>Update</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateNewProptree;
