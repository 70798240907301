import React, { Component, Fragment } from 'react';
import { Disclosure, Menu, Switch, Transition } from '@headlessui/react'
import { AdjustmentsIcon, CashIcon, ChartPieIcon, ChatIcon, ExclamationIcon, PlusIcon } from '@heroicons/react/solid'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import WarningModal from '../components/warningModal';
import SingleSelection from '../components/singleSelection';
import CreateUser from '../components/createUser';
import CreateCampaign from '../components/createCampaign';
import { ArrowNarrowRightIcon, BellIcon, CheckIcon, ChevronDownIcon, QuestionMarkCircleIcon, ClipboardListIcon, CogIcon, CollectionIcon, CreditCardIcon, CurrencyDollarIcon, FolderIcon, InformationCircleIcon, KeyIcon, PaperClipIcon, PlusCircleIcon, RefreshIcon, UserCircleIcon, ViewGridAddIcon, DesktopComputerIcon } from '@heroicons/react/outline';
import CreateFeed from '../components/createFeed';
import Pusher from 'pusher-js';
import { versionRegister } from '../services/versionRegister';
import Input from '../components/input';
import Dropdown from '../components/dropdown';
import timezones from '../assets/timezones.json';
var moment = require('moment');

var pusherService = null;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Enterprise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            open_create_campaign: false,
            open_create_feed: false,
            enterprise: {},
            tab: 0,
            feed: [],
            logs: [],
            adbooster_slogans: [],
            adbooster_plugins: [],
            limit: { id: 0, name: "All listings" },
            blockSync: false,
            syncUser: "",
            timezones: timezones,
            enterprise_sync: {}
        }
    };

    componentDidMount() {

        pusherService = new Pusher('a4ee0d05afe391530eb1', {
            cluster: 'ap1'
        });

        this.functions.getUser();
        this.functions.getEnterprise(window.location.pathname.split("/")[3]);
        this.functions.startGlobalListener();
    }

    componentWillUnmount() {
        pusherService.unsubscribe("admin");
    }

    functions = {
        startGlobalListener: async () => {
            let self = this;
            let channel = pusherService.subscribe("admin");
            channel.bind('syncEnterprise', function (response) {
                console.log(response);
                self.setState({
                    syncing: response.syncing,
                    syncingEnterpriseName: response.enterprise,
                    syncingEnterpriseDev: response.dev
                });
            });
        },
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
        },
        updateEnterprise: async () => {
            try {
                let response = await this.calls.updateEnterprise();
                this.setState({
                    enterprise: response.data,
                    loading_update: false
                })
            } catch (error) {
                this.setState({
                    loading_update: false
                })
            }
        },
        getEnterprise: async (id) => {
            try {
                let response = await this.calls.getEnterprise(id);
                this.setState({
                    enterprise: response.data,
                    loading: false
                }, () => {
                    //set plugins per vertical
                    if (this.state.enterprise.adboosterType.value === 'boostabostad') {
                        this.state.adbooster_plugins = [
                            { id: 2, name: "Only agent listings" },
                            { id: 3, name: "Only agency listings" },
                            { id: 4, name: "Leadform early access" },
                            { id: 5, name: "Leadform more info" },
                            { id: 6, name: "Leadform viewing" },
                            { id: 7, name: "Leadform callback" },
                            { id: 8, name: "Image block" },
                            { id: 9, name: "Pre-qualify" },
                            { id: 10, name: "Signature services" }
                        ]
                    } else {
                        this.state.adbooster_plugins = [
                            { id: 1, name: "Investor visa" },
                            { id: 2, name: "Only agent listings" },
                            { id: 3, name: "Only agency listings" },
                            { id: 4, name: "Leadform early access" },
                            { id: 5, name: "Leadform more info" },
                            { id: 6, name: "Leadform viewing" },
                            { id: 7, name: "Leadform callback" },
                            { id: 8, name: "Image block" },
                            { id: 9, name: "Pre-qualify" },
                            { id: 10, name: "Signature services" }
                        ]
                    }
                    this.setState({
                        adbooster_plugins: this.state.adbooster_plugins
                    });
                });
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        },
        uploadLogo: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadLogo(formData, true).then((response) => {
                        this.state.enterprise.logo = response.data;
                        this.setState({
                            enterprise: this.state.enterprise,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        },
        uploadThemeImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_themeimage: true
                }, () => {
                    this.calls.uploadLogo(formData).then((response) => {
                        this.state.enterprise.themeImage = response.data;
                        this.setState({
                            enterprise: this.state.enterprise,
                            loading_themeimage: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_themeimage: false
                        })
                    });
                });
            }
        },
        uploadFavicon: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_favicon: true
                }, () => {
                    this.calls.uploadLogo(formData, true).then((response) => {
                        this.state.enterprise.favicon = response.data;
                        this.setState({
                            enterprise: this.state.enterprise,
                            loading_favicon: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_favicon: false
                        })
                    });
                });
            }
        },
        removeFeed: async () => {
            try {
                let id = this.state.remove_feed.id;
                this.setState({
                    remove_feed: null,
                }, async () => {
                    let response = await this.calls.removeFeed(id);
                    this.state.enterprise.feeds = this.state.enterprise.feeds.filter((item) => {
                        return item.id != response.data.id;
                    });
                    this.setState({
                        enterprise: this.state.enterprise,
                        loading_remove_feed: false
                    })
                })
            } catch (error) {
                this.setState({
                    loading_remove_feed: false
                })
            }
        },
        updateFeed: async (feed, option) => {
            try {
                this.state.enterprise.feeds = this.state.enterprise.feeds.map((item) => {
                    if (item.id == feed.id) {
                        item.loading_custom = true;
                    }
                    return item;
                });
                await this.promisedSetState({
                    enterprise: this.state.enterprise
                });
                await this.calls.updateFeed({ id: feed.id, custom: option });
                this.state.enterprise.feeds = this.state.enterprise.feeds.map((item) => {
                    if (item.id === feed.id) {
                        item.custom = option;
                    }
                    return item;
                });
                this.promisedSetState({
                    enterprise: this.state.enterprise
                });
            } catch (error) { }
            this.state.enterprise.feeds = this.state.enterprise.feeds.map((item) => {
                if (item.id === feed.id) {
                    item.loading_custom = false;
                }
                return item;
            });
            this.promisedSetState({
                enterprise: this.state.enterprise
            });
        },
        parseCsv: async (feed) => {
            try {
                let response = await this.calls.getCsvFromFeed(feed.id);
                this.setState({
                    feed: response.data,
                    loading: false
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        },
        updateCurrencies: async () => {
            try {
                await this.promisedSetState({
                    loading_currencies: true
                });
                let response = await this.calls.updateEnterpriseCurrencies(this.state.enterprise.id);
                this.state.enterprise.currencies = response.data.currencies;
                this.state.enterprise.currenciesUpdated = response.data.currenciesUpdated;
                await this.promisedSetState({
                    enterprise: this.state.enterprise,
                    loading_currencies: false
                });
            } catch (error) {
                this.promisedSetState({
                    loading_currencies: false
                });
            }
        },
        removeEnterprise: async () => {
            this.setState({
                loading_remove: true,
                remove: null,
            }, async () => {
                try {
                    await this.calls.removeEnterprise(this.state.enterprise.id);
                    this.props.history.push("/dashboard/enterprise");
                } catch (error) {
                    this.setState({
                        loading_remove: null
                    })
                }
            })
        },
        duplicateEnterprise: async () => {
            await this.promisedSetState({
                loading_duplicate: true,
                duplicate: null,
            });
            try {
                await this.calls.duplicateEnterprise(this.state.enterprise.id, this.state.duplicate_agents);
                this.props.history.push("/dashboard/enterprise");
            } catch (error) {
                this.promisedSetState({
                    loading_duplicate: null,
                    duplicate_agents: null
                });
            }
        },
        syncFeed: async () => {
            let self = this;

            await self.promisedSetState({ loading_xml: true, logs: [] });
            var channel = pusherService.subscribe(self.state.user.id);
            channel.bind('syncFeed', function (response) {
                if (response.done) {
                    pusherService.unsubscribe(self.state.user.id);
                    if (response.statistics) {
                        self.state.enterprise.listing_statistics = response.statistics;
                    }
                    self.state.logs.push({ text: response.log, time: response.time });
                    self.promisedSetState({ enterprise: self.state.enterprise, loading_xml: false, logs: self.state.logs });
                } else if (response.loop) {
                    if (self.state.logs.filter((item) => { return item.loop }).length < 1) {
                        self.state.logs.push({ loop: true, text: response.log, time: response.time });
                    } else {
                        self.state.logs = self.state.logs.map((item) => {
                            if (item.loop) {
                                item.text = response.log;
                                item.time = response.time;
                            }
                            return item;
                        })
                    }
                    self.promisedSetState({ logs: self.state.logs });
                } else {
                    console.log(response);
                    self.state.logs.push({ text: response.log, time: response.time });
                    self.promisedSetState({ logs: self.state.logs });
                }
            });

            try {
                await self.calls.syncFeed(self.state.user.id);
            } catch (error) {
                pusherService.unsubscribe(self.state.user.id);
                await self.promisedSetState({ loading_xml: false });
            }
        },
        syncEnterprise: async (step) => {
            let self = this;
            await self.promisedSetState({ loading_sync: true, step: step });
            var channel = pusherService.subscribe(self.state.user.id);
            channel.bind('syncEnterprise', async function (response) {

                if (!self.state.enterprise.enterpriseSyncData) {
                    self.state.enterprise.enterpriseSyncData = {};
                }

                //FIRST
                if (response.start && response.step === 'first') {
                    self.state.enterprise.enterpriseSyncData.first = { logs: [] };
                }
                if ((response.log || response.count) && response.step === 'first') {
                    let exist = false;
                    self.state.enterprise.enterpriseSyncData.first.logs = self.state.enterprise.enterpriseSyncData.first.logs.map((item) => {
                        if (response.id && item.id === response.id) {
                            if (response.log) {
                                item.log = item.log + ". " + response.log;
                            }
                            if (response.count) {
                                item.count = response.count;
                                item.time = response.time;
                            }
                            exist = true;
                        }
                        return item;
                    });
                    if (!exist) {
                        self.state.enterprise.enterpriseSyncData.first.logs.push(response);
                    }
                    await self.promisedSetState({ enterprise: self.state.enterprise });
                }
                if (response.done && response.step === 'first') {
                    if (response.first) {
                        self.state.enterprise.enterpriseSyncData.first.sync = response.first.sync;
                        self.state.enterprise.enterpriseSyncData.first.data = response.first.data;
                        self.state.enterprise.listing_statistics = response.listing_statistics;
                        await self.promisedSetState({ enterprise: self.state.enterprise });
                    }
                }

                //SECOND
                if (response.start && response.step === 'second') {
                    self.state.enterprise.enterpriseSyncData.second = { logs: [] };
                }
                if ((response.log || response.count) && response.step === 'second') {
                    let exist = false;
                    self.state.enterprise.enterpriseSyncData.second.logs = self.state.enterprise.enterpriseSyncData.second.logs.map((item) => {
                        if (item.id === response.id) {
                            if (response.log) {
                                item.log = item.log + ". " + response.log;
                            }
                            if (response.count) {
                                item.count = response.count;
                                item.time = response.time;
                            }
                            exist = true;
                        }
                        return item;
                    });
                    if (!exist) {
                        self.state.enterprise.enterpriseSyncData.second.logs.push(response);
                    }
                    await self.promisedSetState({ enterprise: self.state.enterprise });
                }
                if (response.done && response.step === 'second') {
                    if (response.second) {
                        self.state.enterprise.enterpriseSyncData.second.sync = response.second.sync;
                        self.state.enterprise.enterpriseSyncData.second.data = response.second.data;
                        self.state.enterprise.listing_statistics = response.listing_statistics;
                        await self.promisedSetState({ enterprise: self.state.enterprise });
                    }
                }

                //THIRD
                if (response.start && response.step === 'third') {
                    self.state.enterprise.enterpriseSyncData.third = { logs: [] };
                }
                if (response.log && response.step === 'third') {
                    let exist = false;
                    self.state.enterprise.enterpriseSyncData.third.logs = self.state.enterprise.enterpriseSyncData.third.logs.map((item) => {
                        if (item.id === response.id) {
                            item.log = item.log + ". " + response.log;
                            exist = true;
                        }
                        return item;
                    });
                    if (!exist) {
                        self.state.enterprise.enterpriseSyncData.third.logs.push(response);
                    }
                    await self.promisedSetState({ enterprise: self.state.enterprise });
                }
                if (response.done && response.step === 'third') {
                    if (response.third) {
                        self.state.enterprise.enterpriseSyncData.third.sync = response.third.sync;
                        self.state.enterprise.enterpriseSyncData.third.data = response.third.data;
                        await self.promisedSetState({ enterprise: self.state.enterprise });
                    }
                }

                if (response.finished || response.error) {
                    pusherService.unsubscribe(self.state.user.id);
                    if (response.error && response.step === 'first') {
                        await self.promisedSetState({ first_sync_error: response.error });
                    }
                    if (response.error && response.step === 'second') {
                        await self.promisedSetState({ second_sync_error: response.error });
                    }
                    if (response.error && response.step === 'third') {
                        await self.promisedSetState({ third_sync_error: response.error });
                    }
                    await self.promisedSetState({ loading_sync: false, enterprise_sync: false });
                    self.functions.updateEnterprise();
                }

            });
            try {
                await self.calls.syncEnterprise(self.state.user.id, step);
                await self.promisedSetState({ first_sync_error: false });
                await self.promisedSetState({ second_sync_error: false });
                await self.promisedSetState({ third_sync_error: false });
            } catch (error) {
                pusherService.unsubscribe(self.state.user.id);
                await self.promisedSetState({ loading_sync: false });
            }
        },
        removeListings: async () => {
            this.promisedSetState({ loading_remove_listings: true });
            try {
                let response = await this.calls.removeListings();
                if (response.meta) {
                    this.state.enterprise.listing_statistics = response.meta;
                    this.promisedSetState({ enterprise: this.state.enterprise });
                }
            } catch (error) { }
            this.promisedSetState({ loading_remove_listings: false });
        },
        removeNewListings: async () => {
            this.promisedSetState({ loading_remove_listings: true });
            try {
                let response = await this.calls.removeNewListings();
                if (response.meta) {
                    this.state.enterprise.listing_statistics = response.meta;
                    this.promisedSetState({ enterprise: this.state.enterprise });
                }
            } catch (error) { }
            this.promisedSetState({ loading_remove_listings: false });
        },
        removeNotSupportedListings: async () => {
            this.promisedSetState({ loading_remove_not_supported_listings: true });
            try {
                let response = await this.calls.removeNotSupportedListings();
                if (response.meta) {
                    this.state.enterprise.listing_statistics = response.meta;
                    this.promisedSetState({ enterprise: this.state.enterprise });
                }
            } catch (error) { }
            this.promisedSetState({ loading_remove_not_supported_listings: false });
        },
        syncAdboosterLeads: async () => {
            this.promisedSetState({ loading_adboosterleads: true });
            let self = this;
            let let_id = this.state.user.id;
            let channel = pusherService.subscribe(let_id);
            channel.bind('adboosterleads', function (response) {
                console.log(response);
                if (response.done) {
                    pusherService.unsubscribe(let_id);
                    self.promisedSetState({ loading_adboosterleads: false });
                }
            });
            try {
                await this.calls.adboosterLeads(let_id);
            } catch (error) {
                this.promisedSetState({ loading_adboosterleads: false });
            }
        },
        removeAllLeads: async () => {
            this.promisedSetState({ loading_removeleads: true });
            try {
                await this.calls.removeAllLeads();
            } catch (error) { }
            this.promisedSetState({ loading_removeleads: false });
        },
        report: async () => {
            this.promisedSetState({ loading_report: true });
            try {
                let response = await this.calls.report();
                //this.state.enterprise.report = response.data;
                this.promisedSetState({ enterprise: this.state.enterprise });
            } catch (error) { }
            this.promisedSetState({ loading_report: false });
        },
        actionsAndLeads: async (option) => {
            this.promisedSetState({ loading_actions_leads: true });
            try {
                let response = await this.calls.actionsAndLeads(option);
                this.promisedSetState({ enterprise: this.state.enterprise });
            } catch (error) { }
            this.promisedSetState({ loading_actions_leads: false });
        },
        leadReport: async (option) => {
            this.promisedSetState({ loading_lead_report: true });
            try {
                if (this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") {
                    await this.calls.leadReportLight(option);
                } else {
                    await this.calls.leadReport();
                }
                this.promisedSetState({ enterprise: this.state.enterprise });
            } catch (error) { }
            this.promisedSetState({ loading_lead_report: false });
        },
        createLoginLink: async (user) => {
            this.state.enterprise.users = this.state.enterprise.users.map((item) => {
                if (item.id == user.id) {
                    item.loading = true;
                }
                return item;
            });
            await this.promisedSetState({
                enterprise: this.state.enterprise
            });
            try {
                let response = await this.calls.createLoginLink(user.id);
                if (response.data.token) {
                    if (window.location.hostname === 'localhost') {
                        window.open("http://localhost:5001/login/" + response.data.token);
                    } else if (window.location.hostname === 'app.adbooster.se') {
                        window.open("https://app.adbooster.se/login/" + response.data.token);
                    } else if (window.location.hostname === 'app.adbooster.ai') {
                        window.open("https://app.adbooster.ai/login/" + response.data.token);
                    }
                }
            } catch (error) { }
            this.state.enterprise.users = this.state.enterprise.users.map((item) => {
                if (item.id == user.id) {
                    item.loading = false;
                }
                return item;
            });
            await this.promisedSetState({
                enterprise: this.state.enterprise
            });
        }
    };

    calls = {
        leadReport: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/enterprise/cronHandler/leadReport?enterprise=" + this.state.enterprise.id;
            return apiRegister.call(options, url);
        },
        leadReportLight: (option) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            /*
            let url = 'https://cron.adbooster.se' + "/cron/report?enterprise=" + this.state.enterprise.id + "&type=" + option;
            return apiRegister.call(options, url);
            */
            if (window.location.href.indexOf('localhost') !== -1) {
                let url = 'http://localhost:5002' + "/cron/report?enterprise=" + this.state.enterprise.id + "&type=" + option;
                return apiRegister.call(options, url);
            } else {
                let url = 'https://cron.adbooster.se' + "/cron/report?enterprise=" + this.state.enterprise.id + "&type=" + option;
                return apiRegister.call(options, url);
            }
        },
        adboosterLeads: (pusher_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/syncAdboosterLeads?id=" + this.state.enterprise.id + "&channel=" + pusher_id;
            return apiRegister.call(options, url);
        },
        report: () => {
            let options = apiRegister.options(tokenRegister.get(), 'put', null);
            let url = apiRegister.url.api + "/enterprise/report?id=" + this.state.enterprise.id;
            return apiRegister.call(options, url);
        },
        actionsAndLeads: (option) => {
            let options = apiRegister.options(tokenRegister.get(), 'put', null);
            let url = apiRegister.url.api + "/enterprise/updateActionsAndLeads?id=" + this.state.enterprise.id + (option ? ("&" + option + "=true") : "");
            return apiRegister.call(options, url);
        },
        updateEnterprise: () => {
            console.log(this.state.enterprise);
            let options = apiRegister.options(tokenRegister.get(), 'PUT', this.state.enterprise);
            let url = apiRegister.url.api + "/enterprise/edit/" + this.state.enterprise.id;
            return apiRegister.call(options, url);
        },
        getEnterprise: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/" + id;
            return apiRegister.call(options, url);
        },
        updateEnterpriseCurrencies: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/enterprise/update-currencies/" + id;
            return apiRegister.call(options, url);
        },
        getCsvFromFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed/" + id;
            return apiRegister.call(options, url);
        },
        uploadLogo: (data, png_owerwrite) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image" + (png_owerwrite ? "?png=true" : "");
            return apiRegister.call(options, url);
        },
        removeAllLeads: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'delete', null, null);
            let url = apiRegister.url.api + "/lead/removeAllLeads?enterprise=" + this.state.enterprise.id;
            return apiRegister.call(options, url);
        },
        removeFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'delete', null, null);
            let url = apiRegister.url.api + "/feed/" + id;
            return apiRegister.call(options, url);
        },
        updateFeed: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'put', data, null);
            let url = apiRegister.url.api + "/feed/edit/" + data.id;
            return apiRegister.call(options, url);
        },
        removeEnterprise: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/enterprise/remove/" + id;
            return apiRegister.call(options, url);
        },
        duplicateEnterprise: (id, duplicate_agents) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/enterprise/duplicate/" + id + (duplicate_agents ? "?agents=true" : "");
            return apiRegister.call(options, url);
        },
        syncFeed: (channel) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/listings/sync-feed/" + this.state.enterprise.id + "?channel=" + channel + (this.state.limit.id !== 0 ? ("&limit=" + this.state.limit.value) : "");
            return apiRegister.call(options, url);
        },
        syncEnterprise: (channel, step) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/enterprise/syncEnterprise?enterprise=" + this.state.enterprise.id + "&channel=" + channel + (this.state.limit.id !== 0 ? ("&limit=" + this.state.limit.value) : "") + "&step=" + step;
            return apiRegister.call(options, url);
        },
        removeListings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/listings/remove-listings/" + this.state.enterprise.id + "?status=" + this.state.remove_listings_status;
            return apiRegister.call(options, url);
        },
        removeNewListings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/listings/remove-listings/" + this.state.enterprise.id + "?status=new";
            return apiRegister.call(options, url);
        },
        removeNotSupportedListings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/listings/remove-listings/" + this.state.enterprise.id + "?status=not_supported";
            return apiRegister.call(options, url);
        },
        createLoginLink: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/user/createLoginLink?id=" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        enterpriseType: (enterprise) => {
            let string = '';
            try {
                if (enterprise.version) {
                    string += '<span class="text-indigo-500 uppercase">' + enterprise.version.name + ' • </span>';
                }
                if (enterprise.legacy && enterprise.legacy.value === "enabled") {
                    string += '<span class="text-pink-500 uppercase">LIGHT</span>';
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") !== -1) {
                        string += '<span class="text-pink-500 uppercase"> PROJECT</span>';
                    }
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") == -1) {
                        string += '<span class="text-pink-500 uppercase"> UNIT</span>';
                    }
                    if (enterprise.basicSwitch && enterprise.basicSwitch.value === "enabled") {
                        string += '<span class="text-orange-500 uppercase"> • BASIC</span>';
                    }
                    if (enterprise.creativeSwitch && enterprise.creativeSwitch.value === "enabled") {
                        string += '<span class="text-yellow-600 uppercase"> • CREATIVE</span>';
                    }
                    if (enterprise.proptreeSwitch && enterprise.proptreeSwitch.value === "enabled") {
                        string += '<span class="text-purple-500 uppercase"> • PROPTREE</span>';
                    }
                } else {
                    string += '<span class="text-indigo-500 uppercase">LEGACY</span>';
                }
            } catch (error) { }
            return string;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                {/*ERROR MODAL*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Error"}
                    description={typeof this.state.message == "string" ? this.state.message : "Something went wrong"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*DUPLICATE MODAL*/}
                <WarningModal
                    open={this.state.duplicate ? true : false}
                    title={"Clone Enterprise"}
                    description={"Are you sure you want to clone this enterprise?"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"create"}
                    decisionModal={true}
                    showInput={false}
                    onClose={(value) => {
                        this.setState({ duplicate: null, duplicate_agents: null })
                    }}
                    onSubmit={(value) => {
                        this.functions.duplicateEnterprise();
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Enterprise"}
                    description={"Are you sure you want to delete this enterprise? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove_name != this.state.enterprise.name}
                    showInput={true}
                    copyInput={this.state.enterprise.name}
                    inputTitle={"Type enterprise name to remove"}
                    inputError={(this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.enterprise.name) ? "Enterprise name not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove_name == this.state.enterprise.name) {
                            this.functions.removeEnterprise();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove_feed ? true : false}
                    title={"Delete Feed"}
                    description={"Are you sure you want to delete this feed?"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    onClose={() => {
                        this.setState({
                            remove_feed: null
                        })
                    }}
                    onSubmit={() => {
                        this.setState({
                            loading_remove_feed: true
                        }, () => {
                            this.functions.removeFeed();
                        })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove_listings ? true : false}
                    title={"Delete Listings"}
                    description={"Are you sure you want to delete listings?"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    onClose={() => {
                        this.setState({
                            remove_listings: false,
                            remove_listings_status: null
                        })
                    }}
                    onSubmit={() => {
                        this.setState({
                            remove_listings: false,
                        }, () => {
                            this.functions.removeListings();
                        })
                    }}
                />

                <div className="rounded-lg bg-white overflow-hidden shadow">
                    <h2 className="sr-only" id="profile-overview-title">
                        Profile Overview
                    </h2>
                    <div className="bg-white p-6">
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                    <div>
                                        <img
                                            className="w-full"
                                            src={this.state.enterprise.logo}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                    <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                                        {this.state.enterprise.name}
                                        <span className="ml-2" dangerouslySetInnerHTML={{ __html: this.renders.enterpriseType(this.state.enterprise) }}></span>
                                    </p>
                                    <p className="text-sm font-medium text-gray-600">{this.state.enterprise.companyName}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !this.state.loading &&
                    <main className="max-w-7xl mx-auto pb-10 px-4 lg:py-12 lg:px-8 ">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            <aside className="py-3 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
                                <nav className="space-y-1">
                                    {[
                                        { id: 0, name: 'Information', icon: InformationCircleIcon, },
                                        { id: 2, name: 'Datasources', icon: ClipboardListIcon },
                                        ...(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled"
                                            ? []
                                            : [{ id: 16, name: 'CSV Feeds', icon: ClipboardListIcon }]
                                        ),
                                        //{ id: 16, name: 'CSV Feeds', icon: ClipboardListIcon },
                                        { id: 11, name: 'Cron handler', icon: AdjustmentsIcon },
                                        //{ id: 10, name: 'Listings', icon: CollectionIcon },
                                        { id: 3, name: 'Campaigns', icon: FolderIcon, },
                                        ...(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled"
                                            ? [{ id: 14, name: 'Lead forms', icon: QuestionMarkCircleIcon, }]
                                            : []
                                        ),
                                        //{ id: 14, name: 'Lead forms', icon: QuestionMarkCircleIcon, },
                                        //{ id: 6, name: 'Reports', icon: ChartPieIcon, },
                                        { id: 4, name: 'Agents', icon: UserCircleIcon },
                                        //{ id: 8, name: 'Leads', icon: ChatIcon },
                                        { id: 9, name: 'Currency', icon: CurrencyDollarIcon },
                                        { id: 5, name: 'Settings', icon: CogIcon },
                                        { id: 13, name: 'UI manager', icon: DesktopComputerIcon }
                                        //{ id: 7, name: 'Invoices', icon: CashIcon }
                                    ].map((item) => (
                                        <div
                                            key={item.name}
                                            onClick={() => {
                                                this.setState({
                                                    tab: item.id
                                                })
                                            }}
                                            className={classNames(
                                                item.id == this.state.tab
                                                    ? 'bg-gray-50 text-orange-600 '
                                                    : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50 ',
                                                'group rounded-md px-3 py-2 flex items-center text-sm font-medium hover:bg-white cursor-pointer'
                                            )}
                                            aria-current={item.id == this.state.tab ? 'page' : undefined}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.id == this.state.tab ? 'text-orange-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="truncate">{item.name}</span>
                                        </div>
                                    ))}
                                </nav>
                            </aside>
                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">

                                {/*CRON HANDLER (TOTAL LISTINGS)*/}
                                {
                                    this.state.tab == 11 &&
                                    <div className="mb-8">
                                        <div className="shadow rounded-md">
                                            <div className="bg-white rounded-md py-6 px-4 space-y-6 sm:p-6">
                                                <div className="col-span-6 text-md font-semibold">
                                                    Agents/Listings stats
                                                    <div className="text-gray-500 text-xs font-medium">Last sync: {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first ? moment(this.state.enterprise.enterpriseSyncData.first.sync).format("YYYY-MM-DD hh:mm:ss a") : ""}</div>
                                                </div>
                                                <div className="col-span-6 grid grid-cols-2 sm:grid-cols-10 gap-4">
                                                    {[
                                                        { id: "agents_updated", title: "Agents", name: "Updated", stat: 0 },
                                                        { id: "agents_new", title: "Agents", name: "New", stat: 0 },
                                                        { id: "new", title: "Listings", name: "New", stat: 0 },
                                                        { id: "removed", title: "Listings", name: "Removed", stat: 0 },
                                                        { id: "updated", title: "Listings", name: "Updated", stat: 0 },
                                                        //{ id: "live", title: "Listings", name: "Live", stat: 0 },
                                                        { id: "pending", title: "Listings", name: "Pending", stat: 0 },
                                                        { id: "not_supported", title: "Listings", name: "Not supported", stat: 0 },
                                                        { id: "archived", title: "Listings", name: "Archived", stat: 0 },
                                                        { id: "ready", title: "Listings", name: "Ready", stat: 0 }
                                                    ].map((item) => (
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">{item.title}</div>
                                                            <div className="text-sm">{item.name}</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-purple-600">
                                                                {(this.state.enterprise.listing_statistics && this.state.enterprise.listing_statistics[item.id]) ? this.state.enterprise.listing_statistics[item.id] : 0}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CRON HANDLER (LISTINGS AND AGENTS LATEST STEP)*/}
                                {
                                    this.state.tab == 11 &&
                                    <div className="mb-8">
                                        <div className="shadow rounded-md">
                                            <div className="bg-white rounded-t-md py-6 px-4 space-y-6 sm:p-6">

                                                <div className="col-span-6 text-md font-semibold">
                                                    <div class="flex flex-row flex-1">
                                                        <div className="flex flex-col flex-1">
                                                            Check datasource for updates
                                                            <div className="text-gray-500 text-xs font-medium">Last sync: {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first ? moment(this.state.enterprise.enterpriseSyncData.first.sync).format("YYYY-MM-DD hh:mm:ss a") : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.syncing &&
                                                    <div className="col-span-6 gap-4">
                                                        <div className="rounded-md bg-yellow-100 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-yellow-800">Sync is running</h3>
                                                                    <div className="mt-2 text-sm text-yellow-700">
                                                                        <p>
                                                                            {this.state.syncingEnterpriseName} ({this.state.syncingEnterpriseDev ? "DEV" : "LIVE"}) is running a sync atm, please wait ...
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    <div className="col-span-6 grid grid-cols-2 sm:grid-cols-9 gap-4">
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Agents</div>
                                                            <div className="text-sm">New</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-purple-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data ? this.state.enterprise.enterpriseSyncData.first.data['new_agents'] : "0"}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Agents</div>
                                                            <div className="text-sm">Updated</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-purple-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data ? this.state.enterprise.enterpriseSyncData.first.data['updated_agents'] : "0"}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings assigned</div>
                                                            <div className="text-sm">Fallback agent</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-orange-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data && Array.isArray(this.state.enterprise.enterpriseSyncData.first.data.listings_with_default_agents) ? this.state.enterprise.enterpriseSyncData.first.data.listings_with_default_agents.length : "0"}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings assigned</div>
                                                            <div className="text-sm">Disabled agent</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-orange-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data && Array.isArray(this.state.enterprise.enterpriseSyncData.first.data.listings_with_disabled_agents) ? this.state.enterprise.enterpriseSyncData.first.data.listings_with_disabled_agents.length : "0"}
                                                            </div>
                                                        </div>
                                                        {
                                                            false &&
                                                            <div className="col-span-1">
                                                                <div className="text-xs font-medium">Listings</div>
                                                                <div className="text-sm">Existing</div>
                                                                <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                                    {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data ? this.state.enterprise.enterpriseSyncData.first.data['existing_listings'] : "0"}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings</div>
                                                            <div className="text-sm">New</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data ? this.state.enterprise.enterpriseSyncData.first.data['new_listings'] : "0"}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings</div>
                                                            <div className="text-sm">Updated</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data ? this.state.enterprise.enterpriseSyncData.first.data['updated_listings'] : "0"}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings</div>
                                                            <div className="text-sm">Removed</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-red-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data ? this.state.enterprise.enterpriseSyncData.first.data['removed_listings'] : "0"}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings</div>
                                                            <div className="text-sm">Not supported</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-red-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.data ? this.state.enterprise.enterpriseSyncData.first.data['not_supported_listings'] : "0"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    <div className="col-span-6 pt-0">
                                                        {
                                                            this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && Array.isArray(this.state.enterprise.enterpriseSyncData.first.logs) &&
                                                            this.state.enterprise.enterpriseSyncData.first.logs.map((log) => {
                                                                return (
                                                                    <div className="p-2 border-b text-xxs font-medium flex flex-row items-center">
                                                                        <div className="flex flex-1">
                                                                            {log.log} {log.count}
                                                                        </div>
                                                                        <div className="text-purple-400">
                                                                            {log.time}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            this.state.first_sync_error &&
                                                            <div className="p-2 border-b text-xxs font-medium flex flex-row items-center">
                                                                <div className="flex flex-1 text-red-500">
                                                                    {this.state.first_sync_error}
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.first && this.state.enterprise.enterpriseSyncData.first.error &&
                                                            <div className="p-2 border-b text-xxs font-medium flex flex-row items-center">
                                                                <div className="flex flex-1 text-red-500">
                                                                    {this.state.enterprise.enterpriseSyncData.first.error}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 rounded-b-md text-right sm:px-6 flex flex-row">
                                                <div className="flex flex-1"></div>
                                                <div>
                                                    <span className="relative mr-2 inline-flex shadow-sm rounded-md">
                                                        <button
                                                            type="button"
                                                            className="relative inline-flex items-center px-4 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                        >
                                                            {this.state.limit.name}
                                                        </button>
                                                        <Menu as="span" className="-ml-px relative block">
                                                            <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                                <span className="sr-only">{this.state.limit.name}</span>
                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                            </Menu.Button>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="origin-top-right border absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {[
                                                                            { id: 0, name: "All listings" },
                                                                            { id: 9, name: "1", value: 1 },
                                                                            { id: 9, name: "2", value: 2 },
                                                                            { id: 1, name: "5", value: 5 },
                                                                            { id: 2, name: "10", value: 10 },
                                                                            { id: 3, name: "20", value: 20 },
                                                                            { id: 4, name: "50", value: 50 },
                                                                            { id: 9, name: "60", value: 60 },
                                                                            { id: 5, name: "100", value: 100 },
                                                                            { id: 6, name: "200", value: 200 },
                                                                            { id: 7, name: "300", value: 300 },
                                                                            { id: 8, name: "500", value: 500 }
                                                                        ].map((item) => (
                                                                            <Menu.Item key={item.name}>
                                                                                {({ active }) => (
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                limit: item
                                                                                            })
                                                                                        }}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'block px-4 py-2 text-sm'
                                                                                        )}
                                                                                    >
                                                                                        {item.name}
                                                                                    </div>
                                                                                )}
                                                                            </Menu.Item>
                                                                        ))}
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </span>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.loading_sync) {
                                                                this.functions.syncEnterprise("first_only");
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        {
                                                            this.state.loading_sync && this.state.step === 'first_only' &&
                                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Sync this step
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CRON HANDLER (TOTAL LISTINGS)*/}
                                {
                                    this.state.tab == 11 &&
                                    <div className="mb-8">
                                        <div className="shadow rounded-md">
                                            <div className="bg-white rounded-t-md py-6 px-4 space-y-6 sm:p-6">
                                                <div className="col-span-6 text-md font-semibold">
                                                    <div class="flex flex-row flex-1">
                                                        <div className="flex flex-col flex-1">
                                                            Add, Remove and Update listings
                                                            <div className="text-gray-500 text-xs font-medium">Last sync: {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second ? moment(this.state.enterprise.enterpriseSyncData.second.sync).format("YYYY-MM-DD hh:mm:ss a") : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.syncing &&
                                                    <div className="col-span-6 gap-4">
                                                        <div className="rounded-md bg-yellow-100 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-yellow-800">Sync is running</h3>
                                                                    <div className="mt-2 text-sm text-yellow-700">
                                                                        <p>
                                                                            {this.state.syncingEnterpriseName} ({this.state.syncingEnterpriseDev ? "DEV" : "LIVE"}) is running a sync atm, please wait ...
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    ((Array.isArray(this.state.enterprise.updated_agents) && this.state.enterprise.updated_agents.length > 0) || (this.state.enterprise.listing_statistics && this.state.enterprise.listing_statistics.updatedAgents > 0)) &&
                                                    <div className="col-span-6">
                                                        <div className="bg-red-100 text-red-500 text-sm font-medium p-4 rounded-md">
                                                            New/Updated agents, please approve changes first. <span className="underline font-semibold cursor-pointer hover:text-red-600" onClick={() => {
                                                                this.props.history.push("/dashboard/agents?enterprise=" + this.state.enterprise.id);
                                                            }}>Click here</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    <div className="col-span-6 grid grid-cols-3 sm:grid-cols-8 gap-4">
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings</div>
                                                            <div className="text-sm">Added</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && this.state.enterprise.enterpriseSyncData.second.data ? this.state.enterprise.enterpriseSyncData.second.data['added_new'] : "0"}
                                                                <span className="ml-1 mr-1 text-black">/</span>
                                                                <span className="text-red-500">
                                                                    {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && this.state.enterprise.enterpriseSyncData.second.data ? this.state.enterprise.enterpriseSyncData.second.data['errors_new'] : "0"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings</div>
                                                            <div className="text-sm">Removed</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && this.state.enterprise.enterpriseSyncData.second.data ? this.state.enterprise.enterpriseSyncData.second.data['removed_deleted'] : "0"}
                                                                <span className="ml-1 mr-1 text-black">/</span>
                                                                <span className="text-red-500">
                                                                    {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && this.state.enterprise.enterpriseSyncData.second.data ? this.state.enterprise.enterpriseSyncData.second.data['errors_deleted'] : "0"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="text-xs font-medium">Listings</div>
                                                            <div className="text-sm">Updated</div>
                                                            <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && this.state.enterprise.enterpriseSyncData.second.data ? this.state.enterprise.enterpriseSyncData.second.data['updated'] : "0"}
                                                                <span className="ml-1 mr-1 text-black">/</span>
                                                                <span className="text-red-500">
                                                                    {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && this.state.enterprise.enterpriseSyncData.second.data ? this.state.enterprise.enterpriseSyncData.second.data['errors_updated'] : "0"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && Array.isArray(this.state.enterprise.enterpriseSyncData.second.logs) &&
                                                    <div className="col-span-6 pt-0">
                                                        {
                                                            this.state.enterprise.enterpriseSyncData.second.logs.map((log) => {
                                                                return (
                                                                    <div className="p-2 border-b text-xxs font-medium flex flex-row items-center">
                                                                        <div className="flex flex-1">
                                                                            {log.log}
                                                                        </div>
                                                                        <div className="text-purple-400">
                                                                            {log.time}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.second && this.state.enterprise.enterpriseSyncData.second.error &&
                                                    <div className="col-span-6 pt-0">
                                                        <div className="p-2 border-b text-xxs font-medium flex flex-row items-center">
                                                            <div className="flex flex-1 text-red-500">
                                                                {this.state.enterprise.enterpriseSyncData.second.error}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 rounded-b-md text-right sm:px-6">
                                                <div>
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.loading_sync) {
                                                                this.functions.syncEnterprise("second_only");
                                                            }
                                                        }}
                                                        type="submit"
                                                        className={(true ? " hover:bg-indigo-700" : "bg-opacity-50 cursor-not-allowed") + " bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                    >
                                                        {
                                                            this.state.loading_sync && this.state.step === 'second_only' &&
                                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Sync this step
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CRON HANDLER (TOTAL LISTINGS)*/}
                                {
                                    this.state.tab == 11 &&
                                    <div className="mb-8">
                                        <div className="shadow rounded-md">
                                            <div className="bg-white rounded-t-md py-6 px-4 space-y-6 sm:p-6">
                                                <div className="col-span-6 text-md font-semibold">
                                                    <div class="flex flex-row flex-1">
                                                        <div className="flex flex-col flex-1">
                                                            Update CSV feeds
                                                            <div className="text-gray-500 text-xs font-medium">Last sync: {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.third ? moment(this.state.enterprise.enterpriseSyncData.third.sync).format("YYYY-MM-DD hh:mm:ss a") : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.syncing &&
                                                    <div className="col-span-6 gap-4">
                                                        <div className="rounded-md bg-yellow-100 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-yellow-800">Sync is running</h3>
                                                                    <div className="mt-2 text-sm text-yellow-700">
                                                                        <p>
                                                                            {this.state.syncingEnterpriseName} ({this.state.syncingEnterpriseDev ? "DEV" : "LIVE"}) is running a sync atm, please wait ...
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    <div className="col-span-1">
                                                        <div className="text-xs font-medium">Feeds</div>
                                                        <div className="text-sm">Updated</div>
                                                        <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                            {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.third && this.state.enterprise.enterpriseSyncData.third.data ? this.state.enterprise.enterpriseSyncData.third.data['updated'] : "0"}
                                                            <span className="ml-1 mr-1 text-black">/</span>
                                                            <span className="text-red-500">
                                                                {this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.third && this.state.enterprise.enterpriseSyncData.third.data ? this.state.enterprise.enterpriseSyncData.third.data['errors'] : "0"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.third && Array.isArray(this.state.enterprise.enterpriseSyncData.third.logs) &&
                                                    <div className="col-span-6 pt-0">
                                                        {
                                                            this.state.enterprise.enterpriseSyncData.third.logs.map((log) => {
                                                                return (
                                                                    <div className="p-2 border-b text-xxs font-medium flex flex-row items-center">
                                                                        <div className="flex flex-1">
                                                                            {log.log}
                                                                        </div>
                                                                        <div className="text-purple-400">
                                                                            {log.time}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.state.enterprise.enterpriseSyncData && this.state.enterprise.enterpriseSyncData.third && this.state.enterprise.enterpriseSyncData.third.error &&
                                                    <div className="col-span-6 pt-0">
                                                        <div className="p-2 border-b text-xxs font-medium flex flex-row items-center">
                                                            <div className="flex flex-1 text-red-500">
                                                                {this.state.enterprise.enterpriseSyncData.third.error}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 rounded-b-md text-right sm:px-6">
                                                <div>
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.loading_sync) {
                                                                this.functions.syncEnterprise("third_only");
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        {
                                                            this.state.loading_sync && this.state.step === 'third_only' &&
                                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Sync this step
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CRON HANDLER*/}
                                {
                                    this.state.tab == 11 &&
                                    <div className="mb-8">
                                        <div className="shadow rounded-md">
                                            <div className="bg-white rounded-t-md py-6 px-4 space-y-6 sm:p-6">
                                                <div className="col-span-6 text-md font-semibold">
                                                    Cron settings
                                                    <div className="text-gray-500 text-xs font-medium">Update enterprise settings</div>
                                                </div>
                                                <div className="grid grid-cols-6 gap-4">
                                                    {
                                                        this.state.syncing &&
                                                        <div className="col-span-6">
                                                            <div className="rounded-md bg-yellow-100 p-4">
                                                                <div className="flex">
                                                                    <div className="flex-shrink-0">
                                                                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <h3 className="text-sm font-medium text-yellow-800">Sync is running</h3>
                                                                        <div className="mt-2 text-sm text-yellow-700">
                                                                            <p>
                                                                                {this.state.syncingEnterpriseName} ({this.state.syncingEnterpriseDev ? "DEV" : "LIVE"}) is running a sync atm, please wait ...
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-span-6 sm:col-span-2">
                                                        <label htmlFor="cron-status" className="block text-sm font-medium text-gray-700">
                                                            Enable cron
                                                        </label>
                                                        <div className="mt-1">
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.enableCronSync = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.enableCronSync ? this.state.enterprise.enableCronSync : { id: 0, name: 'Disabled', value: false }}
                                                                options={[
                                                                    { id: 0, name: 'Disabled', value: false },
                                                                    { id: 1, name: 'Enabled', value: true }
                                                                ]}
                                                                id="cron-status"
                                                                name="cron-status"
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.enterprise.enableCronSync && this.state.enterprise.enableCronSync.value &&
                                                        <div className="col-span-6 sm:col-span-2">
                                                            <label htmlFor="cron-recurrence" className="block text-sm font-medium text-gray-700">
                                                                Cron recurrence
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.cronSyncRecurrence = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.cronSyncRecurrence ? this.state.enterprise.cronSyncRecurrence : { id: 1, name: 'Daily', value: 'daily' }}
                                                                    options={[
                                                                        { id: 1, name: 'Daily', value: 'daily' },
                                                                        { id: 2, name: 'Weekly', value: 'weekly' }
                                                                    ]}
                                                                    id="cron-recurrence"
                                                                    name="cron-recurrence"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.cronSyncRecurrence && this.state.enterprise.cronSyncRecurrence.value === "weekly" &&
                                                        this.state.enterprise.enableCronSync && this.state.enterprise.enableCronSync.value &&
                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                            <label htmlFor="cron-weekday" className="block text-sm font-medium text-gray-700">
                                                                Weekday
                                                            </label>
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.runCronSyncWeekday = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.runCronSyncWeekday ? this.state.enterprise.runCronSyncWeekday : { id: 0, name: 'Select weekday', value: false }}
                                                                options={[
                                                                    { id: 1, name: 'Monday', value: 'monday' },
                                                                    { id: 2, name: 'Tuesday', value: 'tuesday' },
                                                                    { id: 3, name: 'Wednesday', value: 'wednesday' },
                                                                    { id: 4, name: 'Thursday', value: 'thursday' },
                                                                    { id: 5, name: 'Friday', value: 'friday' },
                                                                    { id: 7, name: 'Saturday', value: 'saturday' },
                                                                    { id: 8, name: 'Sunday', value: 'sunday' }
                                                                ]}
                                                                id="cron-weekday"
                                                                name="cron-weekday"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.enableCronSync && this.state.enterprise.enableCronSync.value &&
                                                        <div className="col-span-6 sm:col-span-2">
                                                            <label htmlFor="cron-hour" className="block text-sm font-medium text-gray-700">
                                                                Run cron this hour
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.runCronSyncHour = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.runCronSyncHour ? this.state.enterprise.runCronSyncHour : { id: 1, name: '00', value: '00' }}
                                                                    options={[
                                                                        { id: 1, name: '00', value: '00' },
                                                                        { id: 2, name: '01', value: '01' },
                                                                        { id: 3, name: '02', value: '02' },
                                                                        { id: 4, name: '03', value: '03' },
                                                                        { id: 5, name: '04', value: '04' },
                                                                        { id: 6, name: '05', value: '05' },
                                                                        { id: 7, name: '06', value: '06' },
                                                                        { id: 8, name: '07', value: '07' },
                                                                        { id: 9, name: '08', value: '08' },
                                                                        { id: 10, name: '09', value: '09' },
                                                                        { id: 11, name: '10', value: '10' },
                                                                        { id: 12, name: '11', value: '11' },
                                                                        { id: 13, name: '12', value: '12' },
                                                                        { id: 14, name: '13', value: '13' },
                                                                        { id: 15, name: '14', value: '14' },
                                                                        { id: 16, name: '15', value: '15' },
                                                                        { id: 17, name: '16', value: '16' },
                                                                        { id: 18, name: '17', value: '17' },
                                                                        { id: 19, name: '18', value: '18' },
                                                                        { id: 20, name: '19', value: '19' },
                                                                        { id: 21, name: '20', value: '20' },
                                                                        { id: 22, name: '21', value: '21' },
                                                                        { id: 23, name: '22', value: '22' },
                                                                        { id: 24, name: '23', value: '23' }
                                                                    ]}
                                                                    id="cron-hour"
                                                                    name="cron-hour"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.enableCronSync && this.state.enterprise.enableCronSync.value &&
                                                        <div className="col-span-6 sm:col-span-2">
                                                            <label htmlFor="error-notifications" className="block text-sm font-medium text-gray-700">
                                                                Error notifications (WhatsApp)
                                                            </label>
                                                            <input
                                                                value={this.state.enterprise.cronSyncErrorNumber}
                                                                onChange={(event) => {
                                                                    this.state.enterprise.cronSyncErrorNumber = event.target.value;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="error-notifications"
                                                                id="error-notifications"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 rounded-md text-right sm:px-6">
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading) {
                                                            this.setState({
                                                                loading_update: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }


                                {/*BASIC ENTERPRISE INFORMATION*/}
                                {
                                    this.state.tab == 0 &&
                                    <div className="mb-8">
                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

                                                <div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Enterprise type</h3>
                                                    <p className="mt-1 text-sm text-gray-500">#{this.state.enterprise.id}</p>
                                                </div>

                                                <div className="grid grid-cols-10 gap-6">
                                                    <div className="col-span-8 sm:col-span-2">
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <label htmlFor="version" className="block text-sm font-medium text-indigo-500">
                                                                VERSION switch
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.version = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.version ? this.state.enterprise.version : { id: 0, name: 'Select version' }}
                                                                    options={[
                                                                        { id: 1, name: 'Live', value: "live" },
                                                                        { id: 2, name: 'Dev', value: "dev" }
                                                                    ]}
                                                                    name="version"
                                                                    id="version"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-10 sm:col-span-2">
                                                        <label htmlFor="legacy-status" className="block text-sm font-medium text-pink-500">
                                                            LIGHT switch
                                                        </label>
                                                        <SingleSelection
                                                            select={(option) => {
                                                                this.state.enterprise.legacy = option;
                                                                if (option.value == "disabled") {
                                                                    this.state.enterprise.basicSwitch = { id: 2, name: 'Disabled', value: 'disabled' };
                                                                    this.state.enterprise.creativeSwitch = { id: 2, name: 'Disabled', value: 'disabled' };
                                                                    this.state.enterprise.proptreeSwitch = { id: 2, name: 'Disabled', value: 'disabled' };
                                                                }
                                                                this.setState({
                                                                    enterprise: this.state.enterprise
                                                                })
                                                            }}
                                                            selected={this.state.enterprise.legacy ? this.state.enterprise.legacy : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                            options={[
                                                                { id: 1, name: 'Enabled (light)', value: 'enabled' },
                                                                { id: 2, name: 'Disabled (legacy)', value: 'disabled' }
                                                            ]}
                                                            name="legacy-status"
                                                            id="legacy-status"
                                                        />
                                                    </div>
                                                    {
                                                        this.state.enterprise.legacy && this.state.enterprise.legacy.value == 'enabled' &&
                                                        <div className="col-span-10 sm:col-span-2">
                                                            <label htmlFor="legacy-status" className="block text-sm font-medium text-orange-500">
                                                                BASIC switch
                                                            </label>
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.basicSwitch = option;
                                                                    if (option.value == "disabled") {
                                                                        this.state.enterprise.creativeSwitch = { id: 2, name: 'Disabled', value: 'disabled' };
                                                                        this.state.enterprise.proptreeSwitch = { id: 2, name: 'Disabled', value: 'disabled' };
                                                                    }
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.basicSwitch ? this.state.enterprise.basicSwitch : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="legacy-status"
                                                                id="legacy-status"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.legacy && this.state.enterprise.legacy.value == 'enabled' &&
                                                        this.state.enterprise.basicSwitch && this.state.enterprise.basicSwitch.value == 'enabled' &&
                                                        <div className="col-span-10 sm:col-span-2">
                                                            <label htmlFor="legacy-status" className="block text-sm font-medium text-yellow-600">
                                                                CREATIVE switch
                                                            </label>
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.creativeSwitch = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.creativeSwitch ? this.state.enterprise.creativeSwitch : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="legacy-status"
                                                                id="legacy-status"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.legacy && this.state.enterprise.legacy.value == 'enabled' &&
                                                        //this.state.enterprise.basicSwitch && this.state.enterprise.basicSwitch.value == 'enabled' &&
                                                        <div className="col-span-10 sm:col-span-2">
                                                            <label htmlFor="legacy-status" className="block text-sm font-medium text-purple-500">
                                                                PROPTREE switch
                                                            </label>
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.proptreeSwitch = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.proptreeSwitch ? this.state.enterprise.proptreeSwitch : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="legacy-status"
                                                                id="legacy-status"
                                                            />
                                                        </div>
                                                    }
                                                </div>

                                                <div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Information</h3>
                                                    <p className="mt-1 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                                </div>

                                                <div className="grid grid-cols-6 gap-6">
                                                    <div className="col-span-6 grid grid-cols-10 gap-6">
                                                        <div className="col-span-10 sm:col-span-2">
                                                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                                                Name
                                                            </label>
                                                            <input
                                                                value={this.state.enterprise.name ? this.state.enterprise.name : ""}
                                                                onChange={(event) => {
                                                                    this.state.enterprise.name = event.target.value;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                        <div className="col-span-10 sm:col-span-2">
                                                            <label htmlFor="company-name" className="block text-sm font-medium text-gray-700">
                                                                Company name
                                                            </label>
                                                            <input
                                                                value={this.state.enterprise.companyName ? this.state.enterprise.companyName : ""}
                                                                onChange={(event) => {
                                                                    this.state.enterprise.companyName = event.target.value;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="company-name"
                                                                id="company-name"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                        <div className="col-span-10 sm:col-span-2">
                                                            <label htmlFor="parser-id" className="block text-sm font-medium text-gray-700">
                                                                Parser/Creatives ID
                                                            </label>
                                                            <input
                                                                value={this.state.enterprise.parserId ? this.state.enterprise.parserId : ""}
                                                                onChange={(event) => {
                                                                    this.state.enterprise.parserId = event.target.value;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="parser-id"
                                                                id="parser-id"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                        <div className="col-span-10 sm:col-span-2">
                                                            <label htmlFor="company-email" className="block text-sm font-medium text-gray-700">
                                                                Company email
                                                            </label>
                                                            <input
                                                                value={this.state.enterprise.email ? this.state.enterprise.email : ""}
                                                                onChange={(event) => {
                                                                    this.state.enterprise.email = event.target.value;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="company-email"
                                                                id="company-email"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                        {
                                                            this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                            <div className="col-span-10 sm:col-span-2">
                                                                <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                                                                    Company website
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.companyWebsite ? this.state.enterprise.companyWebsite : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.companyWebsite = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    onBlur={(event) => {
                                                                        const enteredValue = event.target.value;
                                                                        const isValidHttpsUrl = /^https:\/\/.+/i.test(enteredValue);
                                                                        const borderColor = isValidHttpsUrl ? 'border-gray-300' : 'border-red-500';
                                                                        event.target.className = "mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm " + borderColor;
                                                                    }}
                                                                    type="text"
                                                                    name="company-website"
                                                                    id="company-website"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                            <div className="col-span-10 sm:col-span-2">
                                                                <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                                                                    Company website
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.companyWebsite ? this.state.enterprise.companyWebsite : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.companyWebsite = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="text"
                                                                    name="company-website"
                                                                    id="company-website"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                            <div className="col-span-10 sm:col-span-2 lg:col-span-2">
                                                                <label htmlFor="append-utm" className="block text-sm font-medium text-gray-700">
                                                                    Auto-append website UTM
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.autoAppendWebsiteUtm = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.autoAppendWebsiteUtm ? this.state.enterprise.autoAppendWebsiteUtm : { id: 1, name: 'Enabled', value: true }}
                                                                    options={[
                                                                        { id: 1, name: 'Enabled', value: true },
                                                                        { id: 2, name: 'Disabled', value: false },
                                                                    ]}
                                                                    name="append-utm"
                                                                    id="append-utm"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                            <div className="col-span-10 sm:col-span-4 lg:col-span-4">
                                                                <label htmlFor="company-bio" className="block text-sm font-medium text-gray-700">
                                                                    Company bio
                                                                </label>
                                                                <textarea
                                                                    value={this.state.enterprise.companyBio ? this.state.enterprise.companyBio : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.companyBio = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    rows="5"
                                                                    maxLength="150"
                                                                    name="company-bio"
                                                                    id="company-bio"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    style={{ resize: "none" }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                                            Status
                                                        </label>
                                                        <SingleSelection
                                                            select={(option) => {
                                                                this.state.enterprise.status = option;
                                                                this.setState({
                                                                    enterprise: this.state.enterprise
                                                                })
                                                            }}
                                                            selected={this.state.enterprise.status ? this.state.enterprise.status : { id: 1, name: 'Active' }}
                                                            options={[
                                                                { id: 1, name: 'Active' },
                                                                { id: 2, name: 'Disabled' },
                                                            ]}
                                                            name="status"
                                                            id="status"
                                                        />
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                                        <label htmlFor="market" className="block text-sm font-medium text-gray-700">
                                                            Market
                                                        </label>
                                                        <SingleSelection
                                                            select={(option) => {
                                                                this.state.enterprise.market = option;
                                                                this.setState({
                                                                    enterprise: this.state.enterprise
                                                                })
                                                            }}
                                                            selected={this.state.enterprise.market ? this.state.enterprise.market : { id: 0, name: 'Select market' }}
                                                            options={[
                                                                { id: 1, name: 'UAE', value: 'ae' },
                                                                { id: 2, name: 'Sweden', value: 'se' },
                                                                { id: 3, name: 'UK', value: 'gb' },
                                                            ]}
                                                            name="market"
                                                            id="market"
                                                        />
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                                        <label htmlFor="time-zone" className="block text-sm font-medium text-gray-700">
                                                            Timezone
                                                        </label>
                                                        <SingleSelection
                                                            noLimit={true}
                                                            select={(option) => {
                                                                this.state.enterprise.timeZone = option;
                                                                this.setState({
                                                                    enterprise: this.state.enterprise
                                                                })
                                                            }}
                                                            selected={this.state.enterprise.timeZone ? this.state.enterprise.timeZone : { id: 0, name: 'Select zone' }}
                                                            options={this.state.timezones.map((item) => {
                                                                return item.utc.map((item) => {
                                                                    return { name: item, value: item };
                                                                });
                                                            }).flat().sort(function (a, b) {
                                                                var textA = a.name.toUpperCase();
                                                                var textB = b.name.toUpperCase();
                                                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                                            })}
                                                            name="time-zone"
                                                            id="time-zone"
                                                        />
                                                    </div>

                                                    <fieldset className="col-span-6">
                                                        <legend className="text-base font-medium text-gray-900">Account</legend>
                                                    </fieldset>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <div className="grid grid-cols-6 gap-6">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="adbooster-id" className="block text-sm font-medium text-gray-700">
                                                                    adBooster ID
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.adboosterId ? this.state.enterprise.adboosterId : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.adboosterId = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="text"
                                                                    name="adbooster-id"
                                                                    id="adbooster-id"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                                        <label htmlFor="adbooster-type" className="block text-sm font-medium text-gray-700">
                                                            adBooster type
                                                        </label>
                                                        <div className="mt-1">
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.adboosterType = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.adboosterType ? this.state.enterprise.adboosterType : {}}
                                                                options={[
                                                                    { id: 1, name: 'Property Booster', value: 'propertybooster', image: "https://res.cloudinary.com/propertybooster/image/upload/v1643467035/xsvt4uklb3evikmdhxca.png" },
                                                                    { id: 2, name: 'Yieldlock', value: 'yieldlock', image: "https://res.cloudinary.com/propertybooster/image/upload/v1643467021/w5tbkagflubbiqb0oilq.png" },
                                                                    { id: 3, name: 'Boosta Bostad', value: 'boostabostad', image: "https://res.cloudinary.com/propertybooster/image/upload/v1643466967/aauqgg4ldqwtktxt5tdt.png" },
                                                                ]}
                                                                name="adbooster-type"
                                                                id="adbooster-type"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <div className="grid grid-cols-6 gap-6">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="primary-color" className="block text-sm font-medium text-gray-700">
                                                                    Primary color
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.primaryColor ? this.state.enterprise.primaryColor : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.primaryColor = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="text"
                                                                    name="primary-color"
                                                                    id="primary-color"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="secondary-color" className="block text-sm font-medium text-gray-700">
                                                                    Secondary color
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.secondaryColor ? this.state.enterprise.secondaryColor : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.secondaryColor = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="text"
                                                                    name="secondary-color"
                                                                    id="secondary-color"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="start-year" className="block text-sm font-medium text-gray-700">
                                                                    Start year
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.startYearBenchmark = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.startYearBenchmark ? this.state.enterprise.startYearBenchmark : { id: 0, name: 'Select year', value: null }}
                                                                        options={[
                                                                            { id: 1, name: '2021', value: "2021" },
                                                                            { id: 2, name: '2022', value: "2022" },
                                                                            { id: 3, name: '2023', value: "2023" },
                                                                            { id: 4, name: '2024', value: "2024" },
                                                                            { id: 5, name: '2025', value: "2025" },
                                                                            { id: 6, name: '2026', value: "2026" },
                                                                            { id: 7, name: '2027', value: "2027" },
                                                                            { id: 8, name: '2028', value: "2028" }
                                                                        ]}
                                                                        name="start-year"
                                                                        id="start-year"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="start-month" className="block text-sm font-medium text-gray-700">
                                                                    Start month
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        noLimit={true}
                                                                        select={(option) => {
                                                                            this.state.enterprise.startMonthBenchmark = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.startMonthBenchmark ? this.state.enterprise.startMonthBenchmark : { id: 0, name: 'Select month', value: null }}
                                                                        options={[
                                                                            { id: 1, name: 'January', value: "01" },
                                                                            { id: 2, name: 'February', value: "02" },
                                                                            { id: 3, name: 'March', value: "03" },
                                                                            { id: 4, name: 'April', value: "04" },
                                                                            { id: 5, name: 'May', value: "05" },
                                                                            { id: 6, name: 'June', value: "06" },
                                                                            { id: 7, name: 'July', value: "07" },
                                                                            { id: 8, name: 'August', value: "08" },
                                                                            { id: 9, name: 'September', value: "09" },
                                                                            { id: 10, name: 'October', value: "10" },
                                                                            { id: 11, name: 'November', value: "11" },
                                                                            { id: 12, name: 'December', value: "12" },
                                                                        ]}
                                                                        name="start-month"
                                                                        id="start-month"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-6">
                                                                <label htmlFor="healthcheck-sales-treshold" className="block text-sm font-medium text-gray-700">
                                                                    Premium SALE listings threshold<span className="ml-1 text-xs">(healthcheck)</span>
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.premiumSalesPriceHealthcheck}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.premiumSalesPriceHealthcheck = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                    name="healthcheck-sales-treshold"
                                                                    id="healthcheck-sales-treshold"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>

                                                            {
                                                                !(this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                <div className="col-span-6 sm:col-span-6">
                                                                    <label htmlFor="healthcheck-rental-treshold" className="block text-sm font-medium text-gray-700">
                                                                        Premium RENTAL listings threshold<span className="ml-1 text-xs">(healthcheck)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.premiumRentalPriceHealthcheck}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.premiumRentalPriceHealthcheck = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="healthcheck-rental-treshold"
                                                                        id="healthcheck-rental-treshold"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            <div className="col-span-6 sm:col-span-6">
                                                                <label htmlFor="healthcheck-min-listings" className="block text-sm font-medium text-gray-700">
                                                                    Minimum active listings<span className="ml-1 text-xs">(healthcheck)</span>
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.minimumActiveListingsHealthcheck}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.minimumActiveListingsHealthcheck = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                    name="healthcheck-min-listings"
                                                                    id="healthcheck-min-listings"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-6">
                                                                <label htmlFor="healthcheck-min-spend-listing" className="block text-sm font-medium text-gray-700">
                                                                    Minimum spend per listing<span className="ml-1 text-xs">(healthcheck)</span>
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.minimumListingSpendHealthcheck}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.minimumListingSpendHealthcheck = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                    name="healthcheck-min-spend-listing"
                                                                    id="healthcheck-min-spend-listing"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-6">
                                                                    <label htmlFor="healthcheck-channels" className="block text-sm font-medium text-gray-700">
                                                                        Ad networks<span className="ml-1 text-xs">(healthcheck)</span>
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.channelsHealthcheck = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.channelsHealthcheck ? this.state.enterprise.channelsHealthcheck : { id: 1, name: '1', value: 1 }}
                                                                            options={[
                                                                                { id: 1, name: '1', value: 1 },
                                                                                { id: 2, name: '2', value: 2 },
                                                                                { id: 3, name: '3', value: 3 },
                                                                                { id: 4, name: '4', value: 4 }
                                                                            ]}
                                                                            name="healthcheck-channel"
                                                                            id="healthcheck-channel"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                                <div className="col-span-6 sm:col-span-6">
                                                                    <label htmlFor="healthcheck-channels" className="block text-sm font-medium text-gray-700">
                                                                        Ad networks<span className="ml-1 text-xs">(healthcheck)</span>
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.channelsHealthcheck = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.channelsHealthcheck ? this.state.enterprise.channelsHealthcheck : { id: 1, name: '1', value: 1 }}
                                                                            options={[
                                                                                { id: 1, name: '1', value: 1 }
                                                                            ]}
                                                                            name="healthcheck-channel"
                                                                            id="healthcheck-channel"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <div className="grid grid-cols-6 gap-6">
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="pixel-id" className="block text-sm font-medium text-gray-700">
                                                                        Facebook Pixel ID
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.facebookPixelId ? this.state.enterprise.facebookPixelId : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.facebookPixelId = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="pixel-id"
                                                                        id="pixel-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="meta-pixel-id" className="block text-sm font-medium text-gray-700">
                                                                        Meta Pixel ID<span className="ml-1 text-xs">(light)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.facebookPixelIdLight ? this.state.enterprise.facebookPixelIdLight : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.facebookPixelIdLight = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="meta-pixel-id"
                                                                        id="meta-pixel-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                                <div className="col-span-6 sm:col-span-6">
                                                                    <label htmlFor="meta-pixel-id" className="block text-sm font-medium text-gray-700">
                                                                        Meta Pixel Conversions API Token<span className="ml-1 text-xs">(needed for CRM events)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.facebookConversionPixelIdLight ? this.state.enterprise.facebookConversionPixelIdLight : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.facebookConversionPixelIdLight = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="meta-pixel-id"
                                                                        id="meta-pixel-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="ga-id" className="block text-sm font-medium text-gray-700">
                                                                        Google Analytics ID
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.googleAnalyticsPixelId ? this.state.enterprise.googleAnalyticsPixelId : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.googleAnalyticsPixelId = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="ga-id"
                                                                        id="ga-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="ga4-id" className="block text-sm font-medium text-gray-700">
                                                                        Google Analytics 4 ID
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.googleAnalytics4PixelId ? this.state.enterprise.googleAnalytics4PixelId : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.googleAnalytics4PixelId = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="ga4-id"
                                                                        id="ga4-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="tiktok-pixel-id" className="block text-sm font-medium text-gray-700">
                                                                        TikTok Pixel ID
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.tiktokPixelId ? this.state.enterprise.tiktokPixelId : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.tiktokPixelId = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="tiktok-pixel-id"
                                                                        id="tiktok-pixel-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="snapchat-pixel-id" className="block text-sm font-medium text-gray-700">
                                                                        Snapchat Pixel ID
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.snapchatPixelId ? this.state.enterprise.snapchatPixelId : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.snapchatPixelId = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="snapchat-pixel-id"
                                                                        id="snapchat-pixel-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="google-ad-account-id" className="block text-sm font-medium text-gray-700">
                                                                        Google Ad Account ID<span className="ml-1 text-xs">(needed for report)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.googleAdaccountId ? this.state.enterprise.googleAdaccountId : ""}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.googleAdaccountId = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="google-ad-account-id"
                                                                        id="google-ad-account-id"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            <div className="col-span-6">
                                                                <label htmlFor="spend" className="block text-sm font-medium text-gray-700">
                                                                    Advertisement spend<span className="ml-1 text-xs">(monthly)</span>
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.advertisementSpend}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.advertisementSpend = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                    name="spend"
                                                                    id="spend"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6">
                                                                    <label htmlFor="portal-visitors" className="block text-sm font-medium text-gray-700">
                                                                        Portals monthly weighted visits<span className="ml-1 text-xs">(opportunity)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.portalsMonthlyWeightedVisitsOpportunity}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.portalsMonthlyWeightedVisitsOpportunity = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="portal-visitors"
                                                                        id="portal-visitors"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="grid grid-cols-6 gap-6">

                                                    {/*LOGO*/}
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                            <div className="flex justify-center relative px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                                {
                                                                    this.state.loading_image &&
                                                                    <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                            class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.logo &&
                                                                    <div style={{ width: "6rem", height: "6rem" }} className="overflow-hidden justify-center flex items-center">
                                                                        <div>
                                                                            <img
                                                                                className="w-full"
                                                                                src={this.state.enterprise.logo}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.logo &&
                                                                    <div style={{ bottom: "15px" }} className="absolute cursor-pointer">
                                                                        <label
                                                                            htmlFor="file-upload"
                                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                        >
                                                                            <span className="cursor-pointer">Update</span>
                                                                            {
                                                                                <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                                                                    onChange={(e) => this.functions.uploadLogo(e)} type="file"
                                                                                    accept={"image/png,image/jpg"}
                                                                                    className="opacity-0 absolute h-full left-0 right-0 top-0 bottom-0 cursor-pointer"
                                                                                    name="file-upload"
                                                                                    id="file-upload"
                                                                                />
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                }
                                                                {
                                                                    !this.state.enterprise.logo &&
                                                                    <div className="space-y-1 text-center">
                                                                        <svg
                                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            viewBox="0 0 48 48"
                                                                            aria-hidden="true"
                                                                        >
                                                                            <path
                                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                        <div className="flex text-sm text-gray-600 cursor-pointer">
                                                                            <label
                                                                                htmlFor="file-upload"
                                                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                            >
                                                                                <span>Upload logo</span>
                                                                                {
                                                                                    <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                                                                        onChange={(e) => this.functions.uploadLogo(e)} type="file"
                                                                                        accept={"image/png,image/jpg"}
                                                                                        className="opacity-0 absolute h-full left-0 right-0 top-0 bottom-0 cursor-pointer"
                                                                                        name="file-upload"
                                                                                        id="file-upload"
                                                                                    />
                                                                                }
                                                                            </label>
                                                                            <p className="pl-1">or drag and drop</p>
                                                                        </div>
                                                                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*PB THEME IMAGE*/}
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                            <div className="flex justify-center relative px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                                {
                                                                    this.state.loading_themeimage &&
                                                                    <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                            class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.themeImage &&
                                                                    <div style={{ width: "6rem", height: "6rem" }} className="overflow-hidden justify-center flex items-center">
                                                                        <div>
                                                                            <img
                                                                                className="w-full"
                                                                                src={this.state.enterprise.themeImage}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.themeImage &&
                                                                    <div style={{ bottom: "15px" }} className="absolute cursor-pointer">
                                                                        <label
                                                                            htmlFor="file-upload"
                                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                        >
                                                                            <span className="cursor-pointer">Update</span>
                                                                            {
                                                                                <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                                                                    onChange={(e) => this.functions.uploadThemeImage(e)} type="file"
                                                                                    accept={"image/png,image/jpg"}
                                                                                    className="opacity-0 absolute h-full left-0 right-0 top-0 bottom-0 cursor-pointer"
                                                                                    name="file-upload"
                                                                                    id="file-upload"
                                                                                />
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                }
                                                                {
                                                                    !this.state.enterprise.themeImage &&
                                                                    <div className="space-y-1 text-center">
                                                                        <svg
                                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            viewBox="0 0 48 48"
                                                                            aria-hidden="true"
                                                                        >
                                                                            <path
                                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                        <div className="flex text-sm text-gray-600 cursor-pointer">
                                                                            <label
                                                                                htmlFor="file-upload"
                                                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                            >
                                                                                <span>Upload theme image</span>
                                                                                {
                                                                                    <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                                                                        onChange={(e) => this.functions.uploadThemeImage(e)} type="file"
                                                                                        accept={"image/png,image/jpg"}
                                                                                        className="opacity-0 absolute h-full left-0 right-0 top-0 bottom-0 cursor-pointer"
                                                                                        name="file-upload"
                                                                                        id="file-upload"
                                                                                    />
                                                                                }
                                                                            </label>
                                                                            <p className="pl-1">or drag and drop</p>
                                                                        </div>
                                                                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*FAVICON*/}
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                            <div className="flex justify-center relative px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                                {
                                                                    this.state.loading_favicon &&
                                                                    <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                            class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.favicon &&
                                                                    <div style={{ width: "6rem", height: "6rem" }} className="rounded-full overflow-hidden justify-center flex items-center">
                                                                        <div>
                                                                            <img
                                                                                className="w-4"
                                                                                src={this.state.enterprise.favicon}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.favicon &&
                                                                    <div style={{ bottom: "15px" }} className="absolute cursor-pointer">
                                                                        <label
                                                                            htmlFor="file-upload"
                                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                        >
                                                                            <span className="cursor-pointer">Update</span>
                                                                            {
                                                                                <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                                                                    onChange={(e) => this.functions.uploadFavicon(e)} type="file"
                                                                                    accept={"image/png,image/jpg"}
                                                                                    className="opacity-0 absolute h-full left-0 right-0 top-0 bottom-0 cursor-pointer"
                                                                                    name="file-upload"
                                                                                    id="file-upload"
                                                                                />
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                }
                                                                {
                                                                    !this.state.enterprise.favicon &&
                                                                    <div className="space-y-1 text-center">
                                                                        <svg
                                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            viewBox="0 0 48 48"
                                                                            aria-hidden="true"
                                                                        >
                                                                            <path
                                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                        <div className="flex text-sm text-gray-600 cursor-pointer">
                                                                            <label
                                                                                htmlFor="file-upload"
                                                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                            >
                                                                                <span>Upload favicon</span>
                                                                                {
                                                                                    <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                                                                        onChange={(e) => this.functions.uploadFavicon(e)} type="file"
                                                                                        accept={"image/png,image/jpg"}
                                                                                        className="opacity-0 absolute h-full left-0 right-0 top-0 bottom-0 cursor-pointer"
                                                                                        name="file-upload"
                                                                                        id="file-upload"
                                                                                    />
                                                                                }
                                                                            </label>
                                                                            <p className="pl-1">or drag and drop</p>
                                                                        </div>
                                                                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex">
                                                <div className="flex flex-1">
                                                    <button
                                                        onClick={() => {
                                                            this.setState({
                                                                remove: true,
                                                                remove_name: ""
                                                            })
                                                        }}
                                                        type="submit"
                                                        className="bg-red-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 mr-2 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                    >
                                                        {
                                                            this.state.loading_remove &&
                                                            <div className="w-full h-full absolute bg-red-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Remove
                                                    </button>
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_duplicate) {
                                                            this.setState({
                                                                duplicate: true
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="border border-transparent mr-2 relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        !this.state.duplicate_agents &&
                                                        this.state.loading_duplicate &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Clone without agents
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_duplicate) {
                                                            this.setState({
                                                                duplicate: true,
                                                                duplicate_agents: true
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="border border-transparent mr-2 relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.duplicate_agents &&
                                                        this.state.loading_duplicate &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Clone with agents
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading) {
                                                            this.setState({
                                                                loading_update: true,
                                                                form_one: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update && this.state.form_one &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*DATASOURCE*/}
                                {
                                    this.state.tab == 2 &&
                                    <div className="mb-8">
                                        <div className="shadow bg-white sm:rounded-md">
                                            <div className="py-6 px-4 space-y-6 sm:p-6">
                                                <div className="flex items-center">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Datasource</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-6 gap-6">
                                                    <div className="col-span-6">
                                                        <label htmlFor="parser-type" className="block text-sm font-medium text-purple-500">
                                                            Parser type
                                                        </label>
                                                        <SingleSelection
                                                            select={(option) => {
                                                                this.state.enterprise.parserType = option;
                                                                this.setState({
                                                                    enterprise: this.state.enterprise
                                                                })
                                                            }}
                                                            selected={this.state.enterprise.parserType ? this.state.enterprise.parserType : { id: 2, name: 'XML parser', value: 'xml' }}
                                                            options={[
                                                                { id: 2, name: 'XML parser', value: 'xml' },
                                                                { id: 6, name: 'XML parser – PROJECTS', value: 'xml_project' },
                                                                { id: 1, name: 'CSV parser – Google Sheets UNITS', value: 'csv_unit' },
                                                                { id: 3, name: 'CSV parser – Google Sheets PROJECTS', value: 'csv_project' },
                                                                { id: 5, name: 'Pixxi CRM – UNITS', value: 'pixxi_unit' },
                                                                { id: 4, name: 'Pixxi CRM – PROJECTS', value: 'pixxi_project' }
                                                            ]}
                                                            name="parser-type"
                                                            id="parser-type"
                                                        />
                                                    </div>
                                                    <div className={(this.state.enterprise.parserType && (this.state.enterprise.parserType.value == "pixxi_project" || this.state.enterprise.parserType.value == "pixxi_unit") ? "sm:col-span-2 lg:col-span-2" : "") + " col-span-6"}>
                                                        {
                                                            this.state.enterprise.parserType && this.state.enterprise.parserType.value !== "pixxi_project" && this.state.enterprise.parserType.value !== "pixxi_unit" &&
                                                            <label htmlFor="datasource" className="block text-sm font-medium text-gray-700">
                                                                Main datasource url
                                                            </label>
                                                        }
                                                        {
                                                            this.state.enterprise.parserType && (this.state.enterprise.parserType.value == "pixxi_project" || this.state.enterprise.parserType.value == "pixxi_unit") &&
                                                            <label htmlFor="datasource" className="block text-sm font-medium text-gray-700">
                                                                Company name in Pixxi
                                                            </label>
                                                        }
                                                        <input
                                                            value={this.state.enterprise.xmlUrl ? this.state.enterprise.xmlUrl : ""}
                                                            onChange={(event) => {
                                                                this.state.enterprise.xmlUrl = event.target.value;
                                                                this.setState({
                                                                    enterprise: this.state.enterprise
                                                                })
                                                            }}
                                                            type="text"
                                                            name="datasource"
                                                            id="datasource"
                                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        />
                                                    </div>
                                                    {
                                                        this.state.enterprise.parserType && (this.state.enterprise.parserType.value == "pixxi_project" || this.state.enterprise.parserType.value == "pixxi_unit") &&
                                                        <div className={(this.state.enterprise.parserType && (this.state.enterprise.parserType.value == "pixxi_project" || this.state.enterprise.parserType.value == "pixxi_unit") ? "sm:col-span-2 lg:col-span-2" : "") + " col-span-6"}>
                                                            <label htmlFor="form-id" className="block text-sm font-medium text-gray-700">
                                                                Form ID (push leads)
                                                            </label>
                                                            <input
                                                                value={this.state.enterprise.formId ? this.state.enterprise.formId : ""}
                                                                onChange={(event) => {
                                                                    this.state.enterprise.formId = event.target.value;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="form-id"
                                                                id="form-id"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.parserType && (this.state.enterprise.parserType.value == "pixxi_project" || this.state.enterprise.parserType.value == "pixxi_unit") &&
                                                        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                                            <label htmlFor="api-token" className="block text-sm font-medium text-gray-700">
                                                                API token
                                                            </label>
                                                            <input
                                                                value={this.state.enterprise.apiToken ? this.state.enterprise.apiToken : ""}
                                                                onChange={(event) => {
                                                                    this.state.enterprise.apiToken = event.target.value;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="api-token"
                                                                id="api-token"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.parserType && (this.state.enterprise.parserType.value == "pixxi_project" || this.state.enterprise.parserType.value == "pixxi_unit") &&
                                                        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                                            <label htmlFor="qr-code-method" className="block text-sm font-medium text-gray-700">
                                                                Select QR code method
                                                            </label>
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.qrCodeMethod = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.qrCodeMethod ? this.state.enterprise.qrCodeMethod : { id: 1, name: 'QR permit URL' }}
                                                                options={[
                                                                    {
                                                                        id: 1,
                                                                        name: "QR permit URL"
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: "QR image URL"
                                                                    }
                                                                ]}
                                                                name="qr-code-method"
                                                                id="qr-code-method"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.parserType && (this.state.enterprise.parserType.value == "pixxi_project" || this.state.enterprise.parserType.value == "pixxi_unit") &&
                                                        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                                            <label htmlFor="listing-status-include" className="block text-sm font-medium text-gray-700">
                                                                Include status
                                                            </label>
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.enterprise.pixxiListingStatusInclude = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.pixxiListingStatusInclude ? this.state.enterprise.pixxiListingStatusInclude : { id: 1, name: 'All' }}
                                                                options={[
                                                                    {
                                                                        id: 1,
                                                                        name: "All"
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: "Active"
                                                                    },
                                                                    {
                                                                        id: 3,
                                                                        name: "Inactive"
                                                                    },
                                                                    {
                                                                        id: 4,
                                                                        name: "Sold"
                                                                    }
                                                                ]}
                                                                name="listing-status-include"
                                                                id="listing-status-include"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.enterprise.parserType && this.state.enterprise.parserType.value !== "pixxi_project" && this.state.enterprise.parserType.value !== "pixxi_unit" &&
                                                        Array.isArray(this.state.enterprise.xmlFeeds) &&
                                                        this.state.enterprise.xmlFeeds.map((item) => {
                                                            return (
                                                                <div className="col-span-12 sm:col-span-12">
                                                                    <div className="flex flex-row">
                                                                        <label htmlFor="extra-datasource" className="block text-sm font-medium text-gray-700">
                                                                            Extra datasource url (inherits parser type)
                                                                        </label>
                                                                        <div className="flex justify-end cursor-pointer flex-1">
                                                                            <div onClick={() => {
                                                                                this.state.enterprise.xmlFeeds = this.state.enterprise.xmlFeeds.filter((inner_item) => {
                                                                                    return inner_item.id !== item.id;
                                                                                });
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }} className="text-sm font-medium text-red-500 hover:text-red-700">
                                                                                Remove
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        value={item.url}
                                                                        onChange={(event) => {
                                                                            item.url = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="text"
                                                                        name="extra-datasource"
                                                                        id="extra-datasource"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.enterprise.parserType && this.state.enterprise.parserType.value !== "pixxi_project" && this.state.enterprise.parserType.value !== "pixxi_unit" &&
                                                    <div onClick={() => {
                                                        if (!Array.isArray(this.state.enterprise.xmlFeeds)) {
                                                            this.state.enterprise.xmlFeeds = [];
                                                        }
                                                        this.state.enterprise.xmlFeeds.push({ id: Math.floor(Math.random() * 10000000000000), url: "", updated: new Date() });
                                                        this.setState({
                                                            enterprise: this.state.enterprise
                                                        })
                                                    }} className="text-sm cursor-pointer inline-flex items-center flex-row font-medium text-purple-500 hover:text-purple-700">
                                                        <PlusCircleIcon className="w-5 h-5 mr-2" /> Add extra datasource
                                                    </div>
                                                }
                                            </div>
                                            <div className="px-4 rounded-b-md py-3 bg-gray-50 text-right sm:px-6">
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_update) {
                                                            this.setState({
                                                                loading_update: true,
                                                                form_two: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update && this.state.form_two &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CSV FEEDS*/}
                                {
                                    this.state.tab == 16 &&
                                    <div className="mb-8">
                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                <div className="flex items-center">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">CSV Feeds</h3>
                                                        <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                this.setState({
                                                                    open_create_feed: true
                                                                })
                                                            }}
                                                            type="button"
                                                            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        >
                                                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.enterprise && Array.isArray(this.state.enterprise.feeds) && this.state.enterprise.feeds.length > 0 &&
                                                    <div className="grid grid-cols-6 gap-6">
                                                        <div className="col-span-6 sm:col-span-6">
                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="feed-custom" className="block text-sm font-medium text-gray-700">
                                                                    Feeds
                                                                </label>
                                                                <dd className="mt-1 text-sm text-gray-900">
                                                                    <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                                        {this.state.enterprise.feeds.map((feed) => (
                                                                            <li
                                                                                key={feed.id}
                                                                                className="pl-3 pr-4 py-3 flex flex-col text-sm sm:flex-row"
                                                                            >
                                                                                <div className="flex-1 flex-row flex justify-center items-center">
                                                                                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                                                                                    <div className="flex flex-1 flex-row">
                                                                                        <span className="justify-start items-center">{feed.publicId}</span>
                                                                                        <span className="ml-3 justify-start items-center font-semibold">{feed.name}</span>
                                                                                        <span className="ml-3 justify-start items-center font-semibold">{feed.channel}</span>
                                                                                        <span className="ml-3 justify-start items-center font-semibold">{feed.property == "both" ? "" : feed.property}</span>
                                                                                        <span className="ml-3 justify-start items-center font-semibold">{feed.theme ? feed.theme.name : ""}</span>
                                                                                        <span className="ml-3 justify-start items-center font-semibold">{feed.utm}</span>
                                                                                        {
                                                                                            feed.fileExist &&
                                                                                            <span className="ml-3 justify-start items-center font-semibold">(File exist on server)</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-row mt-2 sm:mt-0 items-center">
                                                                                    <div className="mr-4">
                                                                                        <div className="text-xxs">Custom</div>
                                                                                        <div className="relative">
                                                                                            <SingleSelection
                                                                                                select={(option) => {
                                                                                                    this.functions.updateFeed(feed, option);
                                                                                                }}
                                                                                                selected={feed.custom && feed.custom.name ? feed.custom : { id: 0, name: "Disabled", value: "disabled" }}
                                                                                                options={[
                                                                                                    { id: 0, name: "Disabled", value: "disabled" },
                                                                                                    { id: 1, name: "Enabled", value: "enabled" }
                                                                                                ]}
                                                                                                name="feed-custom"
                                                                                                id="feed-custom"
                                                                                            />
                                                                                            {
                                                                                                feed.loading_custom &&
                                                                                                <div className="w-full h-full absolute bg-white bg-opacity-50 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <div onClick={() => {
                                                                                            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                                                                return navigator.clipboard.writeText(feed.filePath);
                                                                                            }
                                                                                        }} className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer">
                                                                                            Copy Link
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="ml-4 flex-shrink-0">
                                                                                        <div onClick={() => {
                                                                                            //this.functions.parseCsv(feed);
                                                                                            if (feed.fileExist) {
                                                                                                window.location.assign("/dashboard/feeds/" + feed.id + "?enterprise=" + this.state.enterprise.id);
                                                                                            }
                                                                                        }} className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer">
                                                                                            Open
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="ml-4 flex-shrink-0">
                                                                                        <div onClick={() => {
                                                                                            this.setState({
                                                                                                remove_feed: feed
                                                                                            })
                                                                                        }} className="font-medium text-red-600 hover:text-red-500 cursor-pointer">
                                                                                            Remove
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </dd>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CSV FEED PARSED*/}
                                {
                                    this.state.tab == 2 &&
                                    Array.isArray(this.state.feed) && this.state.feed.length > 0 &&
                                    <div className="flex flex-col">
                                        <div className="w-full">
                                            <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-lg">
                                                <table className="bg-white min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-white">
                                                        <tr>
                                                            {
                                                                Object.keys(this.state.feed[0]).map((key) => {
                                                                    return (
                                                                        <th
                                                                            style={{ whiteSpace: "nowrap" }}
                                                                            scope="col"
                                                                            className="px-6 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                        >
                                                                            {key}
                                                                        </th>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {this.state.feed.map((item, index) => (
                                                            <tr key={index}>
                                                                {
                                                                    Object.keys(item).map((key) => {
                                                                        return (
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item[key]}</td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*LISTINGS*/}
                                {
                                    this.state.tab == 10 &&
                                    <div className="mb-8">
                                        <div className="">
                                            <div className="shadow bg-white sm:rounded-md">
                                                <div className="flex items-center py-6 px-4 sm:p-6">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Listings</h3>
                                                        {
                                                            this.state.enterprise.landingPageCreativeTheme && this.state.enterprise.landingPageCreativeLanguage && this.state.enterprise.landingPageCreativeCurrency &&
                                                            <p className="mt-1 text-sm text-gray-500">Last sync with datasource: {this.state.enterprise.lastSync ? moment(this.state.enterprise.lastSync).format("YYYY-MM-DD HH:MM") : "null"}</p>
                                                        }
                                                        {
                                                            !(this.state.enterprise.landingPageCreativeTheme && this.state.enterprise.landingPageCreativeLanguage && this.state.enterprise.landingPageCreativeCurrency) &&
                                                            <p className="mt-1 text-sm text-red-500">First set landing creative in settings (theme, language & currency) </p>
                                                        }
                                                    </div>
                                                    <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                                        <button
                                                            type="button"
                                                            className="relative inline-flex items-center px-4 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                        >
                                                            {this.state.limit.name}
                                                        </button>
                                                        <Menu as="span" className="-ml-px relative block">
                                                            <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                                <span className="sr-only">{this.state.limit.name}</span>
                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                            </Menu.Button>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="origin-top-right border absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {[
                                                                            { id: 0, name: "All listings" },
                                                                            { id: 9, name: "1", value: 1 },
                                                                            { id: 1, name: "5", value: 5 },
                                                                            { id: 2, name: "10", value: 10 },
                                                                            { id: 3, name: "20", value: 20 },
                                                                            { id: 4, name: "50", value: 50 },
                                                                            { id: 9, name: "60", value: 60 },
                                                                            { id: 5, name: "100", value: 100 },
                                                                            { id: 6, name: "200", value: 200 },
                                                                            { id: 7, name: "300", value: 300 },
                                                                            { id: 8, name: "500", value: 500 }
                                                                        ].map((item) => (
                                                                            <Menu.Item key={item.name}>
                                                                                {({ active }) => (
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                limit: item
                                                                                            })
                                                                                        }}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'block px-4 py-2 text-sm'
                                                                                        )}
                                                                                    >
                                                                                        {item.name}
                                                                                    </div>
                                                                                )}
                                                                            </Menu.Item>
                                                                        ))}
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </span>
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.blockSync && this.state.enterprise.landingPageCreativeTheme && this.state.enterprise.landingPageCreativeLanguage && this.state.enterprise.landingPageCreativeCurrency) {
                                                                if (!this.state.loading_xml) {
                                                                    this.functions.syncFeed();
                                                                }
                                                            }
                                                        }}
                                                        type="button"
                                                        className={((this.state.enterprise.landingPageCreativeTheme && this.state.enterprise.landingPageCreativeLanguage && this.state.enterprise.landingPageCreativeCurrency) ? " cursor-pointer" : " cursor-not-allowed opacity-50") + " relative inline-flex overflow-hidden items-center px-4 py-2 ml-2 rounded-md  bg-indigo-500 text-sm font-medium text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"}
                                                    >
                                                        Sync feed
                                                        {
                                                            this.state.loading_xml &&
                                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                                {
                                                    this.state.blockSync &&
                                                    <div className="px-4 pb-4">
                                                        <div className="rounded-md bg-yellow-100 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-yellow-800">Sync is running</h3>
                                                                    <div className="mt-2 text-sm text-yellow-700">
                                                                        <p>
                                                                            {this.state.syncUser} is running a sync atm, please wait ...
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="grid grid-cols-6 gap-6">
                                                    <div className="col-span-6 sm:col-span-6">
                                                        <div>
                                                            <dl className="border-t grid grid-cols-1 bg-white overflow-hidden divide-y divide-gray-200 md:grid-cols-8 md:divide-y-0 md:divide-x">
                                                                {[
                                                                    { id: "new", name: "New", stat: 0 },
                                                                    { id: "removed", name: "Removed", stat: 0 },
                                                                    { id: "updated", name: "Updated", stat: 0 },
                                                                    //{ id: "live", name: "Live", stat: 0 },
                                                                    { id: "pending", name: "Pending", stat: 0 },
                                                                    { id: "not_supported", name: "Not supported", stat: 0 },
                                                                    { id: "archived", name: "Archived", stat: 0 },
                                                                    { id: "ready", name: "Ready", stat: 0 }
                                                                ].map((item) => (
                                                                    <div key={item.name} className="px-4 py-5 sm:p-6">
                                                                        <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                                                                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                                                            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                                                                {(this.state.enterprise.listing_statistics && this.state.enterprise.listing_statistics[item.id]) ? this.state.enterprise.listing_statistics[item.id] : 0}
                                                                            </div>
                                                                        </dd>
                                                                    </div>
                                                                ))}
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-1 px-4 bg-gray-50 border-t py-4 rounded-b-md">
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.loading_remove_listings) {
                                                                this.setState({
                                                                    remove_listings: true,
                                                                    remove_listings_status: "new"
                                                                })
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="bg-red-600 mr-2 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                    >
                                                        {
                                                            this.state.loading_remove_listings && this.state.remove_listings_status == "new" &&
                                                            <div className="w-full h-full absolute bg-red-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Remove all "new"
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.loading_remove_listings) {
                                                                this.setState({
                                                                    remove_listings: true,
                                                                    remove_listings_status: "not_supported"
                                                                })
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="bg-red-600 mr-2 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                    >
                                                        {
                                                            this.state.loading_remove_listings && this.state.remove_listings_status == "not_supported" &&
                                                            <div className="w-full h-full absolute bg-red-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Remove all "not supported"
                                                    </button>
                                                    {
                                                        this.state.enterprise && this.state.enterprise.version && this.state.enterprise.version.id === 2 &&
                                                        <button
                                                            onClick={() => {
                                                                if (!this.state.loading_remove_listings) {
                                                                    this.setState({
                                                                        remove_listings: true,
                                                                        remove_listings_status: "archived"
                                                                    })
                                                                }
                                                            }}
                                                            type="submit"
                                                            className="bg-red-600 mr-2 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                        >
                                                            {
                                                                this.state.loading_remove_listings && this.state.remove_listings_status == "archived" &&
                                                                <div className="w-full h-full absolute bg-red-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                            Remove all "archived"
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.tab == 10 &&
                                    this.state.logs.length > 0 &&
                                    <div className="mb-8">
                                        <div className="shadow bg-white sm:rounded-md">
                                            <div className="">
                                                <div className="flex items-center py-6 px-4 sm:p-6 border-b">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Logs</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Live sync logs</p>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    {
                                                        this.state.logs.map((log) => {
                                                            return (
                                                                <div className="p-4 px-6 border-b text-sm font-medium flex flex-row items-center">
                                                                    <div className="flex flex-1">
                                                                        {log.text}
                                                                    </div>
                                                                    <div className="text-purple-400">
                                                                        {log.time}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CAMPAIGNS*/}
                                {
                                    this.state.tab == 3 &&
                                    <div className="mb-8">
                                        <div className="shadow sm:rounded-md bg-white">
                                            <div className=" py-6 px-4 space-y-6 sm:p-6">
                                                <div className="flex items-center">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Campaigns</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                                    </div>
                                                </div>
                                                {
                                                    Array.isArray(this.state.enterprise.campaigns) &&
                                                    this.state.enterprise.campaigns.map((item) => {
                                                        return (
                                                            <div className="grid grid-cols-6 gap-6">
                                                                <div className="col-span-6 sm:col-span-2">
                                                                    <Input
                                                                        rightLabel={this.state.enterprise.campaigns.length > 0}
                                                                        label={"Campaign ID"}
                                                                        onRemove={() => {
                                                                            if (!this.state.loading_update) {
                                                                                this.state.enterprise.campaigns = this.state.enterprise.campaigns.filter((inner_item) => {
                                                                                    return inner_item.id !== item.id
                                                                                });
                                                                                this.setState({
                                                                                    loading_update: true,
                                                                                    enterprise: this.state.enterprise
                                                                                }, () => {
                                                                                    this.functions.updateEnterprise();
                                                                                })
                                                                            }
                                                                        }}
                                                                        onChange={(value) => {
                                                                            item.campaign = value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        value={item.campaign}
                                                                        type={"text"}
                                                                    />
                                                                </div>
                                                                <div className="col-span-6 sm:col-span-2">
                                                                    <label htmlFor="ad-type" className="block mb-1 text-sm font-medium text-gray-700">
                                                                        Type
                                                                    </label>
                                                                    <SingleSelection
                                                                        label={"Type"}
                                                                        selected={item.type ? item.type : { id: 0, name: 'Select type' }}
                                                                        options={[
                                                                            { id: 1, name: 'Feed' },
                                                                            { id: 2, name: 'Regular' }
                                                                        ]}
                                                                        searchInput={false}
                                                                        select={(option) => {
                                                                            item.type = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        name="ad-type"
                                                                        id="ad-type"
                                                                    />
                                                                </div>
                                                                <div className="col-span-6 sm:col-span-2">
                                                                    <label htmlFor="channel" className="block mb-1 text-sm font-medium text-gray-700">
                                                                        Channel
                                                                    </label>
                                                                    {
                                                                        this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                                        <SingleSelection
                                                                            label={"Channel"}
                                                                            selected={item.channel ? item.channel : { id: 0, name: 'Select channel' }}
                                                                            options={[
                                                                                { id: 1, name: 'Meta', value: 'facebook' },
                                                                                //{ id: 2, name: 'Google', value: 'google' },
                                                                                //{ id: 3, name: 'Snapchat', value: 'snapchat' },
                                                                                //{ id: 4, name: 'Tik Tok', value: 'tiktok' }
                                                                            ]}
                                                                            searchInput={false}
                                                                            select={(option) => {
                                                                                item.channel = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            name="channel"
                                                                            id="channel"
                                                                        />
                                                                    }
                                                                    {
                                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                        <SingleSelection
                                                                            label={"Channel"}
                                                                            selected={item.channel ? item.channel : { id: 0, name: 'Select channel' }}
                                                                            options={[
                                                                                { id: 1, name: 'Facebook', value: 'facebook' },
                                                                                { id: 2, name: 'Google', value: 'google' },
                                                                                { id: 3, name: 'Snapchat', value: 'snapchat' },
                                                                                { id: 4, name: 'Tik Tok', value: 'tiktok' }
                                                                            ]}
                                                                            searchInput={false}
                                                                            select={(option) => {
                                                                                item.channel = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            name="channel"
                                                                            id="channel"
                                                                        />
                                                                    }
                                                                </div>
                                                                {
                                                                    this.state.enterprise.report && this.state.enterprise.report[item.channel.value] && this.state.enterprise.report[item.channel.value][item.campaign] &&
                                                                    <div className="col-span-6">
                                                                        <div className="rounded-md bg-green-100 p-4">
                                                                            <div className="flex">
                                                                                <div className="">
                                                                                    <h3 className="text-sm font-medium text-green-800">Report total</h3>
                                                                                    <div className="mt-2 text-sm text-green-700">
                                                                                        {
                                                                                            Object.keys(this.state.enterprise.report[item.channel.value][item.campaign]).map((datespan) => {
                                                                                                return (
                                                                                                    <p>{datespan}: {JSON.stringify(this.state.enterprise.report[item.channel.value][item.campaign][datespan].total)}</p>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div onClick={() => {
                                                    if (!Array.isArray(this.state.enterprise.campaigns)) {
                                                        this.state.enterprise.campaigns = [];
                                                    }
                                                    this.state.enterprise.campaigns.push({ id: Math.floor(Math.random() * 99999999), campaign: "", type: { id: 0, name: "select type" }, channel: { id: 0, name: "select channel" } });
                                                    this.setState({
                                                        enterprise: this.state.enterprise
                                                    });
                                                }} className="text-sm inline-flex flex-row font-medium cursor-pointer text-purple-500 hover:text-purple-700 mt-2">
                                                    <PlusIcon className="w-5 h-5 mr-2" /> Add campaign
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 flex flex-row sm:px-6 rounded-b-md">
                                                <button
                                                    onClick={() => {
                                                        this.functions.report();
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_report &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Update REPORT
                                                </button>
                                                <div className="flex flex-1"></div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_update) {
                                                            this.setState({
                                                                loading_update: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*FORMS*/}
                                {
                                    this.state.tab == 14 &&
                                    <div className="mb-8">
                                        <div className="shadow sm:rounded-md bg-white">
                                            <div className=" py-6 px-4 space-y-6 sm:p-6">
                                                <div className="flex items-center">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Lead forms</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                                    </div>
                                                </div>
                                                {
                                                    Array.isArray(this.state.enterprise.leadForms) &&
                                                    this.state.enterprise.leadForms.map((item) => {
                                                        return (
                                                            <div className="grid grid-cols-6 gap-6">
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <Input
                                                                        rightLabel={this.state.enterprise.leadForms.length > 0}
                                                                        label={"Lead form ID"}
                                                                        onRemove={() => {
                                                                            if (!this.state.loading_update) {
                                                                                this.state.enterprise.leadForms = this.state.enterprise.leadForms.filter((inner_item) => {
                                                                                    return inner_item.id !== item.id
                                                                                });
                                                                                this.setState({
                                                                                    loading_update: true,
                                                                                    enterprise: this.state.enterprise
                                                                                }, () => {
                                                                                    this.functions.updateEnterprise();
                                                                                })
                                                                            }
                                                                        }}
                                                                        onChange={(value) => {
                                                                            item.form = value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        value={item.form}
                                                                        type={"text"}
                                                                    />
                                                                </div>
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="channel" className="block mb-1 text-sm font-medium text-gray-700">
                                                                        Channel
                                                                    </label>
                                                                    <SingleSelection
                                                                        label={"Channel"}
                                                                        selected={item.channel ? item.channel : { id: 0, name: 'Select channel' }}
                                                                        options={[
                                                                            { id: 1, name: 'Meta', value: 'facebook' },
                                                                            //{ id: 2, name: 'Google', value: 'google' },
                                                                            //{ id: 3, name: 'Snapchat', value: 'snapchat' },
                                                                            //{ id: 4, name: 'Tik Tok', value: 'tiktok' }
                                                                        ]}
                                                                        searchInput={false}
                                                                        select={(option) => {
                                                                            item.channel = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        name="channel"
                                                                        id="channel"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div onClick={() => {
                                                    if (!Array.isArray(this.state.enterprise.leadForms)) {
                                                        this.state.enterprise.leadForms = [];
                                                    }
                                                    this.state.enterprise.leadForms.push({ id: Math.floor(Math.random() * 99999999), form: "", channel: { id: 0, name: "select channel" } });
                                                    this.setState({
                                                        enterprise: this.state.enterprise
                                                    });
                                                }} className="text-sm inline-flex flex-row font-medium cursor-pointer text-purple-500 hover:text-purple-700 mt-2">
                                                    <PlusIcon className="w-5 h-5 mr-2" /> Add form
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 flex flex-row sm:px-6 rounded-b-md">
                                                <div className="flex flex-1"></div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_update) {
                                                            this.setState({
                                                                loading_update: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*AGENTS*/}
                                {
                                    this.state.tab == 4 &&
                                    <section aria-labelledby="billing-history-heading">
                                        <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="px-4 sm:px-6 flex items-center">
                                                <div className="flex flex-1 flex-col">
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Agents</h3>
                                                    <p className="mt-1 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={() => {
                                                            this.setState({
                                                                open: true,
                                                                selected: null
                                                            })
                                                        }}
                                                        type="button"
                                                        className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-6 flex flex-col">
                                                {
                                                    Array.isArray(this.state.enterprise.users) && this.state.enterprise.users.length > 0 &&
                                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                            <div className="overflow-hidden border-t border-gray-200">
                                                                <table className="min-w-full">
                                                                    <thead className="bg-gray-50 hidden">
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                            >
                                                                                User
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                            >
                                                                                <span className="sr-only">Actions</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                                        {this.state.enterprise.users && this.state.enterprise.users.map((user) => (
                                                                            <tr key={user.id}>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                                    <div className="flex items-center">
                                                                                        {
                                                                                            !user.image &&
                                                                                            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                                </svg>
                                                                                            </span>
                                                                                        }
                                                                                        {
                                                                                            user.image &&
                                                                                            <div className="flex-shrink-0 h-10 w-10">
                                                                                                <img className="h-10 w-10 rounded-full" src={user.image} alt="" />
                                                                                            </div>
                                                                                        }
                                                                                        <div className="ml-4">
                                                                                            <div className="text-sm font-medium capitalize">
                                                                                                <span className="text-gray-900">{user.name}</span>
                                                                                                {user.access && user.access.id === 1 && <span className="text-purple-500 ml-2">Manager</span>}
                                                                                                {user.access && user.access.id === 2 && <span className="text-gray-500 ml-2">Regular</span>}
                                                                                            </div>
                                                                                            <div className="text-sm text-gray-500">{user.email}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                                    <div className="flex-row min-w-64 flex justify-end">
                                                                                        <div onClick={() => {
                                                                                            //this.props.history.push("/login/" + user.id);
                                                                                            if (!user.loading) {
                                                                                                this.functions.createLoginLink(user);
                                                                                            }
                                                                                        }} className="text-indigo-500 hover:text-indigo-900 cursor-pointer mr-6">
                                                                                            Login as User
                                                                                        </div>
                                                                                        <div onClick={() => {
                                                                                            this.setState({
                                                                                                selected: user
                                                                                            }, () => {
                                                                                                this.setState({
                                                                                                    open: true
                                                                                                })
                                                                                            })
                                                                                        }} className="text-indigo-500 hover:text-indigo-900 cursor-pointer">
                                                                                            Edit
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </section>
                                }


                                {/*SETTINGS*/}
                                {
                                    this.state.tab == 5 &&
                                    <div className="mb-8">
                                        <div className="shadow sm:rounded-md">
                                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                <div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Settings</h3>
                                                    <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                                </div>
                                                <div className="grid grid-cols-6 gap-6">

                                                    <fieldset className="col-span-6">
                                                        <legend className="text-base font-medium text-gray-900">Fallbacks</legend>
                                                        <div className="grid grid-cols-6 gap-6  mt-4">
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="agent-rule" className="block text-sm font-medium text-gray-700">
                                                                    Rules
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.agentRule = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.agentRule ? this.state.enterprise.agentRule : { id: 0, name: 'Select Rule' }}
                                                                    options={[
                                                                        { id: 1, name: 'Use fallback Agent if no match (Rule 1)' },
                                                                        { id: 2, name: 'Always use fallback Agent (Rule 2)' },
                                                                        { id: 3, name: 'Rule 1 + Use fallback Email (Rule 3)' },
                                                                        { id: 4, name: 'Rule 1 + Use fallback Phone (Rule 4)' },
                                                                        { id: 5, name: 'Rule 1 + Use fallback Email + Phone (Rule 5)' }
                                                                    ]}
                                                                    name="agent-rule"
                                                                    id="agent-rule"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="fallback-agent" className="block text-sm font-medium text-gray-700">
                                                                    Fallback agent
                                                                    <span className="text-red-500 ml-1 text-xs">(change will update all listings)</span>
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.defaultAgent = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    noLimit={true}
                                                                    searchInput={true}
                                                                    selected={this.state.enterprise.defaultAgent ? this.state.enterprise.defaultAgent : { id: 0, name: 'Select agent' }}
                                                                    options={this.state.enterprise.users}
                                                                    name="fallback-agent"
                                                                    id="fallback-agent"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-2">
                                                                <label htmlFor="fallback-email" className="block text-sm font-medium text-gray-700">
                                                                    Fallback email
                                                                    {
                                                                        this.state.enterprise.agentRule && (this.state.enterprise.agentRule.id === 3 || this.state.enterprise.agentRule.id === 5) &&
                                                                        <span className="text-red-500 ml-1 text-xs">(change will update all listings)</span>
                                                                    }
                                                                </label>
                                                                <input
                                                                    disabled={!this.state.enterprise.agentRule || (this.state.enterprise.agentRule && (this.state.enterprise.agentRule.id != 3 && this.state.enterprise.agentRule.id != 5))}
                                                                    value={this.state.enterprise.defaultAgentEmail ? this.state.enterprise.defaultAgentEmail : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.defaultAgentEmail = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="text"
                                                                    name="fallback-email"
                                                                    id="fallback-email"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-2">
                                                                <label htmlFor="fallback-phone" className="block text-sm font-medium text-gray-700">
                                                                    Fallback phone
                                                                    {
                                                                        this.state.enterprise.agentRule && (this.state.enterprise.agentRule.id === 4 || this.state.enterprise.agentRule.id === 5) &&
                                                                        <span className="text-red-500 ml-1 text-xs">(change will update all listings)</span>
                                                                    }
                                                                </label>
                                                                <input
                                                                    disabled={!this.state.enterprise.agentRule || (this.state.enterprise.agentRule && (this.state.enterprise.agentRule.id != 4 && this.state.enterprise.agentRule.id != 5))}
                                                                    value={this.state.enterprise.defaultAgentPhone ? this.state.enterprise.defaultAgentPhone : ""}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.defaultAgentPhone = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="text"
                                                                    name="fallback-phone"
                                                                    id="fallback-phone"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-2">
                                                                <label htmlFor="fallback-lang" className="block text-sm font-medium text-gray-700">
                                                                    Fallback language
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.baseLanguage = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.baseLanguage ? this.state.enterprise.baseLanguage : { id: 1, name: 'English', value: "en" }}
                                                                        options={[
                                                                            { id: 1, name: 'English', value: "en" },
                                                                            { id: 2, name: 'Swedish', value: "sv" },
                                                                            { id: 3, name: 'Arabic', value: "ar" },
                                                                            { id: 4, name: 'German', value: "de" },
                                                                            { id: 5, name: 'Spanish', value: "es" },
                                                                            { id: 6, name: 'French', value: "fr" },
                                                                            { id: 7, name: 'Russian', value: "ru" },
                                                                            { id: 8, name: 'Dutch', value: "nl" },
                                                                            { id: 9, name: 'Portuguese', value: "pt" },
                                                                            { id: 10, name: 'Italian', value: "it" },
                                                                        ]}
                                                                        name="fallback-lang"
                                                                        id="fallback-lang"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-2">
                                                                <label htmlFor="create-agents-datasource" className="block text-sm font-medium text-gray-700">
                                                                    Create agents from datasource
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.createAgentsFromXml = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.createAgentsFromXml ? this.state.enterprise.createAgentsFromXml : { id: 2, name: 'No', value: false }}
                                                                        options={[
                                                                            { id: 1, name: 'Yes', value: true },
                                                                            { id: 2, name: 'No', value: false }
                                                                        ]}
                                                                        name="create-agents-datasource"
                                                                        id="create-agents-datasource"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-2">
                                                                <label htmlFor="fallback-permit" className="block text-sm font-medium text-gray-700">
                                                                    Fallback permit<span className="ml-1 text-xs">(from fallback agent)</span>
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.useManagerPermit = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.useManagerPermit ? this.state.enterprise.useManagerPermit : { id: 2, name: 'Disabled', value: false }}
                                                                        options={[
                                                                            { id: 2, name: 'Disabled', value: false },
                                                                            { id: 1, name: 'Enabled', value: true }
                                                                        ]}
                                                                        name="fallback-permit"
                                                                        id="fallback-permit"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                <div className="col-span-6 sm:col-span-2">
                                                                    <label htmlFor="auto-approve-agents" className="block text-sm font-medium text-gray-700">
                                                                        Auto approve agents
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.autoApproveAgents = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.autoApproveAgents ? this.state.enterprise.autoApproveAgents : { id: 0, name: 'Disabled' }}
                                                                        options={[
                                                                            {
                                                                                id: 0,
                                                                                value: "disabled",
                                                                                name: "Disabled"
                                                                            },
                                                                            {
                                                                                id: 2,
                                                                                value: "enabled",
                                                                                name: "Enabled"
                                                                            }
                                                                        ]}
                                                                        name="auto-approve-agents"
                                                                        id="auto-approve-agents"
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </fieldset>

                                                    {
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">CRM notifications</legend>
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="grid grid-cols-6 gap-6 mt-4">
                                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="send-api-status" className="block text-sm font-medium text-gray-700">
                                                                            Send through API
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.crmSettignsApi = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.crmSettignsApi ? this.state.enterprise.crmSettignsApi : { id: 0, name: 'Disabled', value: false }}
                                                                                options={[
                                                                                    { id: 0, name: 'Disabled', value: false },
                                                                                    { id: 1, name: 'Enabled', value: true }
                                                                                ]}
                                                                                name="send-api-status"
                                                                                id="send-api-status"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.enterprise.crmSettignsApi && this.state.enterprise.crmSettignsApi.value &&
                                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="lead-notifications" className="block text-sm font-medium text-gray-700">
                                                                                Lead notifications
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.enterprise.crmSettignsLead = option;
                                                                                        this.setState({
                                                                                            enterprise: this.state.enterprise
                                                                                        })
                                                                                    }}
                                                                                    selected={this.state.enterprise.crmSettignsLead ? this.state.enterprise.crmSettignsLead : { id: 0, name: 'Select type', value: false }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Qualified', value: 'qualified' },
                                                                                        { id: 2, name: 'Post-qualified', value: 'double-qualified' },
                                                                                        { id: 3, name: 'Qualified & Post-qualified', value: 'all_qualified' },
                                                                                        { id: 4, name: 'Pre-qualified (phone), Qualified & Post-qualified', value: 'all_qualified_extra' }
                                                                                    ]}
                                                                                    name="lead-notifications"
                                                                                    id="lead-notifications"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                                <div className="grid grid-cols-6 gap-6 mt-4">
                                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="send-api-status" className="block text-sm font-medium text-gray-700">
                                                                            Send through API
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.crmSettignsApi = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.crmSettignsApi ? this.state.enterprise.crmSettignsApi : { id: 0, name: 'Disabled', value: false }}
                                                                                options={[
                                                                                    { id: 0, name: 'Disabled', value: false },
                                                                                    { id: 1, name: 'Enabled', value: true }
                                                                                ]}
                                                                                name="send-api-status"
                                                                                id="send-api-status"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.enterprise.crmSettignsApi && this.state.enterprise.crmSettignsApi.value &&
                                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="lead-notifications" className="block text-sm font-medium text-gray-700">
                                                                                Lead notifications
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.enterprise.crmSettignsLead = option;
                                                                                        this.setState({
                                                                                            enterprise: this.state.enterprise
                                                                                        })
                                                                                    }}
                                                                                    selected={this.state.enterprise.crmSettignsLead ? this.state.enterprise.crmSettignsLead : { id: 0, name: 'Select type', value: false }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Unqualified or Pre-qualified', value: 'unqualified_prequalified' },
                                                                                        { id: 2, name: 'Qualified or Post-qualified (starred)', value: 'qualified_double_qualified' },
                                                                                        { id: 3, name: 'Post-qualified (starred)', value: 'double_qualified' },
                                                                                        //{ id: 3, name: 'Qualified & Post-qualified', value: 'all_qualified' },
                                                                                        //{ id: 4, name: 'Pre-qualified (phone), Qualified & Post-qualified', value: 'all_qualified_extra' }
                                                                                    ]}
                                                                                    name="lead-notifications"
                                                                                    id="lead-notifications"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </fieldset>
                                                    }

                                                    {
                                                        //LEGACY
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">Generic search campaign</legend>
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="google-campaign-status" className="block text-sm font-medium text-gray-700">
                                                                        Google
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.googleCampaignEnable = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.googleCampaignEnable ? this.state.enterprise.googleCampaignEnable : { id: 0, name: 'Disabled', value: false }}
                                                                            options={[
                                                                                { id: 0, name: 'Disabled', value: false },
                                                                                { id: 1, name: 'Enabled', value: true }
                                                                            ]}
                                                                            name="google-campaign-status"
                                                                            id="google-campaign-status"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }
                                                    {
                                                        //LEGACY
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">WhatsApp flow</legend>
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="flow-status" className="block text-sm font-medium text-gray-700">
                                                                        Flow
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.whatsAppFlow = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.whatsAppFlow ? this.state.enterprise.whatsAppFlow : { id: 1, name: 'Disabled', value: "disabled" }}
                                                                        options={[
                                                                            { id: 1, name: 'Disabled', value: "disabled" },
                                                                            { id: 2, name: 'Enabled', value: "enabled" }
                                                                        ]}
                                                                        name="flow-status"
                                                                        id="flow-status"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }
                                                    {
                                                        //LEGACY
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">WhatsApp concierge</legend>
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="whatsapp-concierge-status" className="block text-sm font-medium text-gray-700">
                                                                        Concierge
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.whatsappConcierge = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.whatsappConcierge ? this.state.enterprise.whatsappConcierge : { id: 1, name: 'Disabled', value: false }}
                                                                        options={[
                                                                            { id: 1, name: 'Disabled', value: false },
                                                                            { id: 2, name: 'Enabled', value: true }
                                                                        ]}
                                                                        name="whatsapp-concierge-status"
                                                                        id="whatsapp-concierge-status"
                                                                    />
                                                                </div>
                                                                {
                                                                    this.state.enterprise.whatsappConcierge && this.state.enterprise.whatsappConcierge.value &&
                                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="whatsapp-concierge-number" className="block text-sm font-medium text-gray-700">
                                                                            WhatsApp number
                                                                        </label>
                                                                        <input
                                                                            value={this.state.enterprise.whatsappConciergeNumber}
                                                                            onChange={(event) => {
                                                                                this.state.enterprise.whatsappConciergeNumber = event.target.value;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            type="text"
                                                                            name="whatsapp-concierge-number"
                                                                            id="whatsapp-concierge-number"
                                                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </fieldset>
                                                    }
                                                    {
                                                        //LEGACY
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">Legacy conversion trigger</legend>
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="purchase-event-status" className="block text-sm font-medium text-gray-700">
                                                                        Trigger purchase event on rating<span className="text-red-500 ml-1 text-xs">(enable switch landing)</span>
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.ratingModeLegacy = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.ratingModeLegacy ? this.state.enterprise.ratingModeLegacy : { id: 1, name: 'Disabled', value: false }}
                                                                        options={[
                                                                            { id: 1, name: 'Disabled', value: "disabled" },
                                                                            { id: 2, name: 'Enabled', value: "enabled" }
                                                                        ]}
                                                                        name="purchase-event-status"
                                                                        id="purchase-event-status"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }
                                                    {
                                                        //LEGACY
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">WhatsApp qualification</legend>
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="post-qualify-status" className="block text-sm font-medium text-gray-700">
                                                                        Post qualification
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.postQualification = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.postQualification ? this.state.enterprise.postQualification : { id: 1, name: 'Disabled', value: false }}
                                                                        options={[
                                                                            { id: 1, name: 'Disabled', value: false },
                                                                            { id: 2, name: 'Enabled', value: true }
                                                                        ]}
                                                                        name="post-qualify-status"
                                                                        id="post-qualify-status"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }

                                                    {
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">WhatsApp lead notifications</legend>
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="grid grid-cols-6 gap-6 mt-4">

                                                                    {
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                                                                                Send these lead types
                                                                            </label>
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.sendWhatsappLeadType = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.sendWhatsappLeadType ? this.state.enterprise.sendWhatsappLeadType : { id: 0, name: 'Select type', value: false }}
                                                                                options={[
                                                                                    { id: 1, name: 'Qualified', value: 'qualified' },
                                                                                    { id: 2, name: 'Post-qualified', value: 'double-qualified' },
                                                                                    { id: 3, name: 'Qualified & Post-qualified', value: 'all_qualified' },
                                                                                    { id: 4, name: 'Pre-qualified (phone), Qualified & Post-qualified', value: 'all_qualified_extra' }
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                                                                                Send notifications centralized WhatsApp
                                                                            </label>
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.centralizedWhatsapp = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.centralizedWhatsapp ? this.state.enterprise.centralizedWhatsapp : { id: 1, name: 'Disabled', value: false }}
                                                                                options={[
                                                                                    { id: 1, name: 'Disabled', value: false },
                                                                                    { id: 2, name: 'Enabled', value: true }
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.enterprise.centralizedWhatsapp && this.state.enterprise.centralizedWhatsapp.value &&
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="centralized-whatsapp-number" className="block text-sm font-medium text-gray-700">
                                                                                Centralized WhatsApp number
                                                                            </label>
                                                                            <input
                                                                                value={this.state.enterprise.centralizedWhatsappNumber}
                                                                                onChange={(event) => {
                                                                                    this.state.enterprise.centralizedWhatsappNumber = event.target.value;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                type="text"
                                                                                name="centralized-whatsapp-number"
                                                                                id="centralized-whatsapp-number"
                                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="notifications-agent-status" className="block text-sm font-medium text-gray-700">
                                                                                Send notifications agents WhatsApp
                                                                            </label>
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.brokersWhatsapp = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.brokersWhatsapp ? this.state.enterprise.brokersWhatsapp : { id: 1, name: 'Disabled', value: false }}
                                                                                options={[
                                                                                    { id: 1, name: 'Disabled', value: false },
                                                                                    { id: 2, name: 'Enabled', value: true }
                                                                                ]}
                                                                                name="notifications-agent-status"
                                                                                id="notifications-agent-status"
                                                                            />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                                <div className="grid grid-cols-6 gap-6 mt-4">

                                                                    {
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="lead-types" className="block text-sm font-medium text-gray-700">
                                                                                Send these lead types
                                                                            </label>
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.sendWhatsappLeadType = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.sendWhatsappLeadType ? this.state.enterprise.sendWhatsappLeadType : { id: 0, name: 'Select type', value: false }}
                                                                                options={[
                                                                                    { id: 1, name: 'Unqualified or Pre-qualified', value: 'unqualified_prequalified' },
                                                                                    { id: 2, name: 'Qualified or Post-qualified (starred)', value: 'qualified_double_qualified' },
                                                                                    { id: 3, name: 'Post-qualified (starred)', value: 'double_qualified' },
                                                                                    //{ id: 2, name: 'Post-qualified', value: 'double-qualified' },
                                                                                    //{ id: 3, name: 'Qualified & Post-qualified', value: 'all_qualified' },
                                                                                    //{ id: 4, name: 'Pre-qualified (phone), Qualified & Post-qualified', value: 'all_qualified_extra' }
                                                                                ]}
                                                                                name="lead-types"
                                                                                id="lead-types"
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="notifications-centralized-status" className="block text-sm font-medium text-gray-700">
                                                                                Send notifications centralized WhatsApp
                                                                            </label>
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.centralizedWhatsapp = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.centralizedWhatsapp ? this.state.enterprise.centralizedWhatsapp : { id: 1, name: 'Disabled', value: false }}
                                                                                options={[
                                                                                    { id: 1, name: 'Disabled', value: false },
                                                                                    { id: 2, name: 'Enabled', value: true }
                                                                                ]}
                                                                                name="notifications-centralized-status"
                                                                                id="notifications-centralized-status"
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.enterprise.centralizedWhatsapp && this.state.enterprise.centralizedWhatsapp.value &&
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="centralized-agent" className="block text-sm font-medium text-gray-700">
                                                                                Centralized agent<span className="ml-1 text-xs">(login link)</span>
                                                                            </label>
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.sendWhatsappLeadCentralizedAgent = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                noLimit={true}
                                                                                searchInput={true}
                                                                                selected={this.state.enterprise.sendWhatsappLeadCentralizedAgent ? this.state.enterprise.sendWhatsappLeadCentralizedAgent : { id: 0, name: 'Select agent' }}
                                                                                options={this.state.enterprise.users}
                                                                                name="centralized-agent"
                                                                                id="centralized-agent"
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.enterprise.centralizedWhatsapp && this.state.enterprise.centralizedWhatsapp.value &&
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="centralized-whatsapp-number" className="block text-sm font-medium text-gray-700">
                                                                                Centralized WhatsApp number
                                                                            </label>
                                                                            <input
                                                                                value={this.state.enterprise.centralizedWhatsappNumber}
                                                                                onChange={(event) => {
                                                                                    this.state.enterprise.centralizedWhatsappNumber = event.target.value;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                type="text"
                                                                                name="centralized-whatsapp-number"
                                                                                id="centralized-whatsapp-number"
                                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {
                                                                        <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                            <label htmlFor="notifications-agent-status" className="block text-sm font-medium text-gray-700">
                                                                                Send notifications agents WhatsApp
                                                                            </label>
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.enterprise.brokersWhatsapp = option;
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }}
                                                                                selected={this.state.enterprise.brokersWhatsapp ? this.state.enterprise.brokersWhatsapp : { id: 1, name: 'Disabled', value: false }}
                                                                                options={[
                                                                                    { id: 1, name: 'Disabled', value: false },
                                                                                    { id: 2, name: 'Enabled', value: true }
                                                                                ]}
                                                                                name="notifications-agent-status"
                                                                                id="notifications-agent-status"
                                                                            />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }
                                                        </fieldset>
                                                    }

                                                    {
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">WhatsApp lead report</legend>
                                                            <div className="grid grid-cols-6 gap-6 mt-4">

                                                                <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="lead-report-status" className="block text-sm font-medium text-gray-700">
                                                                        Lead report
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.sendWhatsappReport = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.sendWhatsappReport ? this.state.enterprise.sendWhatsappReport : { id: 1, name: 'Disabled', value: false }}
                                                                        options={[
                                                                            { id: 1, name: 'Disabled', value: false },
                                                                            { id: 2, name: 'Enabled', value: true }
                                                                        ]}
                                                                        name="lead-report-status"
                                                                        id="lead-report-status"
                                                                    />
                                                                </div>
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="lead-report-centralized" className="block text-sm font-medium text-gray-700">
                                                                            Send lead report centralized WhatsApp
                                                                        </label>
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.sendWhatsappReportCentralized = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.sendWhatsappReportCentralized ? this.state.enterprise.sendWhatsappReportCentralized : { id: 1, name: 'Disabled', value: false }}
                                                                            options={[
                                                                                { id: 1, name: 'Disabled', value: false },
                                                                                { id: 2, name: 'Enabled', value: true }
                                                                            ]}
                                                                            name="lead-report-centralized"
                                                                            id="lead-report-centralized"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="report-agent-whatsapp" className="block text-sm font-medium text-gray-700">
                                                                            Send lead report agents WhatsApp
                                                                        </label>
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.sendWhatsappReportAgents = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.sendWhatsappReportAgents ? this.state.enterprise.sendWhatsappReportAgents : { id: 1, name: 'Disabled', value: false }}
                                                                            options={[
                                                                                { id: 1, name: 'Disabled', value: false },
                                                                                { id: 2, name: 'Enabled', value: true }
                                                                            ]}
                                                                            name="report-agent-whatsapp"
                                                                            id="report-agent-whatsapp"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="lead-report-weekday" className="block text-sm font-medium text-gray-700">
                                                                            Send lead report this weekday
                                                                        </label>
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.sendWhatsappReportWeekday = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.sendWhatsappReportWeekday ? this.state.enterprise.sendWhatsappReportWeekday : { id: 0, name: 'Select weekday', value: false }}
                                                                            options={[
                                                                                { id: 1, name: 'Monday', value: 'monday' },
                                                                                { id: 2, name: 'Tuesday', value: 'tuesday' },
                                                                                { id: 3, name: 'Wednesday', value: 'wednesday' },
                                                                                { id: 4, name: 'Thursday', value: 'thursday' },
                                                                                { id: 5, name: 'Friday', value: 'friday' },
                                                                                { id: 7, name: 'Saturday', value: 'saturday' },
                                                                                { id: 8, name: 'Sunday', value: 'sunday' }
                                                                            ]}
                                                                            name="lead-report-weekday"
                                                                            id="lead-report-weekday"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="lead-report-hour" className="block text-sm font-medium text-gray-700">
                                                                            Send lead report this hour
                                                                        </label>
                                                                        <SingleSelection
                                                                            noLimit={true}
                                                                            select={(option) => {
                                                                                this.state.enterprise.sendWhatsappReportTime = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.sendWhatsappReportTime ? this.state.enterprise.sendWhatsappReportTime : { id: 0, name: 'Select time', value: false }}
                                                                            options={[
                                                                                { id: 1, name: '00', value: '00' },
                                                                                { id: 2, name: '01', value: '01' },
                                                                                { id: 3, name: '02', value: '02' },
                                                                                { id: 4, name: '03', value: '03' },
                                                                                { id: 5, name: '04', value: '04' },
                                                                                { id: 6, name: '05', value: '05' },
                                                                                { id: 7, name: '06', value: '06' },
                                                                                { id: 8, name: '07', value: '07' },
                                                                                { id: 9, name: '08', value: '08' },
                                                                                { id: 10, name: '09', value: '09' },
                                                                                { id: 11, name: '10', value: '10' },
                                                                                { id: 12, name: '11', value: '11' },
                                                                                { id: 13, name: '12', value: '12' },
                                                                                { id: 14, name: '13', value: '13' },
                                                                                { id: 15, name: '14', value: '14' },
                                                                                { id: 16, name: '15', value: '15' },
                                                                                { id: 17, name: '16', value: '16' },
                                                                                { id: 18, name: '17', value: '17' },
                                                                                { id: 19, name: '18', value: '18' },
                                                                                { id: 20, name: '19', value: '19' },
                                                                                { id: 21, name: '20', value: '20' },
                                                                                { id: 22, name: '21', value: '21' },
                                                                                { id: 23, name: '22', value: '22' },
                                                                                { id: 24, name: '23', value: '23' }
                                                                            ]}
                                                                            name="lead-report-hour"
                                                                            id="lead-report-hour"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="lead-report-trigger" className="block text-sm font-medium text-gray-700">
                                                                            Lead report trigger condition
                                                                        </label>
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.sendWhatsappReportMinimumLimit = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.sendWhatsappReportMinimumLimit ? this.state.enterprise.sendWhatsappReportMinimumLimit : { id: 0, name: 'Always send', value: 'always' }}
                                                                            options={[
                                                                                { id: 0, name: 'Always send', value: 'always' },
                                                                                { id: 1, name: 'Min 1 pre-qualified', value: 'pre-qualified' },
                                                                                { id: 2, name: 'Min 1 qualified', value: 'qualified' },
                                                                                { id: 3, name: 'Min 1 post-qualified', value: 'double-qualified' },
                                                                                { id: 4, name: 'Min 1 unrated lead', value: 'unrated' }
                                                                            ]}
                                                                            name="lead-report-trigger"
                                                                            id="lead-report-trigger"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="lead-report-recurrence" className="block text-sm font-medium text-gray-700">
                                                                            Lead report recurrence
                                                                        </label>
                                                                        <SingleSelection
                                                                            noLimit={true}
                                                                            select={(option) => {
                                                                                this.state.enterprise.sendWhatsappReportRecurrence = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.sendWhatsappReportRecurrence ? this.state.enterprise.sendWhatsappReportRecurrence : { id: 0, name: 'Select type', value: false }}
                                                                            options={[
                                                                                //{ id: 1, name: 'Monthly', value: 'monthly' },
                                                                                { id: 2, name: 'Weekly', value: 'weekly' },
                                                                                //{ id: 3, name: 'Daily', value: 'daily' }
                                                                            ]}
                                                                            name="lead-report-recurrence"
                                                                            id="lead-report-recurrence"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    this.state.enterprise.sendWhatsappReportCentralized && this.state.enterprise.sendWhatsappReportCentralized.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="centralized-whatsapp-number" className="block text-sm font-medium text-gray-700">
                                                                            Centralized WhatsApp number
                                                                        </label>
                                                                        <input
                                                                            value={this.state.enterprise.sendWhatsappReportCentralizedNumber}
                                                                            onChange={(event) => {
                                                                                this.state.enterprise.sendWhatsappReportCentralizedNumber = event.target.value;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            type="text"
                                                                            name="centralized-whatsapp-number"
                                                                            id="centralized-whatsapp-number"
                                                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    this.state.enterprise.sendWhatsappReportCentralized && this.state.enterprise.sendWhatsappReportCentralized.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <label htmlFor="centralized-agent" className="block text-sm font-medium text-gray-700">
                                                                            Centralized agent<span className="ml-1 text-xs">(login link)</span>
                                                                        </label>
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.sendWhatsappReportCentralizedAgent = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            noLimit={true}
                                                                            searchInput={true}
                                                                            selected={this.state.enterprise.sendWhatsappReportCentralizedAgent ? this.state.enterprise.sendWhatsappReportCentralizedAgent : { id: 0, name: 'Select agent' }}
                                                                            options={this.state.enterprise.users}
                                                                            name="centralized-agent"
                                                                            id="centralized-agent"
                                                                        />
                                                                    </div>
                                                                }
                                                                {/*LEGACY*/}
                                                                {
                                                                    !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <div className="block mb-1 text-sm font-medium text-gray-700">
                                                                            Test
                                                                        </div>
                                                                        <button
                                                                            onClick={() => {
                                                                                if (!this.state.loading_lead_report) {
                                                                                    this.functions.leadReport();
                                                                                }
                                                                            }}
                                                                            className="w-full flex overflow-hidden relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                        >
                                                                            {
                                                                                this.state.loading_lead_report &&
                                                                                <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            Run lead report
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    (this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    this.state.enterprise.sendWhatsappReportCentralized && this.state.enterprise.sendWhatsappReportCentralized.value &&
                                                                    <div className="hidden md:hidden lg:grid col-span-10 sm:col-span-3 lg:col-span-2"></div>
                                                                }
                                                                {
                                                                    (this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    this.state.enterprise.sendWhatsappReportCentralized && this.state.enterprise.sendWhatsappReportCentralized.value &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <div className="block mb-1 text-sm font-medium text-gray-700">
                                                                            Test (centralized)
                                                                        </div>
                                                                        <button
                                                                            onClick={() => {
                                                                                if (!this.state.loading_lead_report) {
                                                                                    this.functions.leadReport("centralized");
                                                                                }
                                                                            }}
                                                                            className="w-full flex overflow-hidden relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                        >
                                                                            {
                                                                                this.state.loading_lead_report &&
                                                                                <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            Run lead report Centralized
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    (this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                    this.state.enterprise.sendWhatsappReport && this.state.enterprise.sendWhatsappReport.value &&
                                                                    this.state.enterprise.sendWhatsappReportAgents && this.state.enterprise.sendWhatsappReportAgents.value &&
                                                                    (this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                    <div className="col-span-10 sm:col-span-3 lg:col-span-2">
                                                                        <div className="block mb-1 text-sm font-medium text-gray-700">
                                                                            Test (agents)
                                                                        </div>
                                                                        <button
                                                                            onClick={() => {
                                                                                if (!this.state.loading_lead_report) {
                                                                                    this.functions.leadReport("agents");
                                                                                }
                                                                            }}
                                                                            className="w-full flex overflow-hidden relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                        >
                                                                            {
                                                                                this.state.loading_lead_report &&
                                                                                <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            Run lead report Agents
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </fieldset>
                                                    }

                                                    {
                                                        false &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">Agents</legend>
                                                            <div className="grid grid-cols-10 gap-6 mt-4">
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="save-images-jpg" className="block text-sm font-medium text-gray-700">
                                                                        Save agent image as JPG
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.saveAgentImageToJpg = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.saveAgentImageToJpg ? this.state.enterprise.saveAgentImageToJpg : { id: 1, name: 'No' }}
                                                                        options={[
                                                                            { value: false, name: "Disabled" },
                                                                            { value: true, name: "Enabled" },
                                                                        ]}
                                                                        name="save-images-jpg"
                                                                        id="save-images-jpg"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }

                                                    <fieldset className="col-span-6">
                                                        <legend className="text-base font-medium text-gray-900">Listings</legend>
                                                        <div className="grid grid-cols-10 gap-6 mt-4">
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="save-images" className="block text-sm font-medium text-gray-700">
                                                                    Save images
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.saveImagesInternal = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.saveImagesInternal ? this.state.enterprise.saveImagesInternal : { id: 1, name: 'No' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="save-images"
                                                                    id="save-images"
                                                                />
                                                            </div>
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="agent-first-name" className="block text-sm font-medium text-gray-700">
                                                                    Only agent first name
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.onlySaveAgentFirstName = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.onlySaveAgentFirstName ? this.state.enterprise.onlySaveAgentFirstName : { id: 1, name: 'No' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="agent-first-name"
                                                                    id="agent-first-name"
                                                                />
                                                            </div>
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="ignore-update-agents" className="block text-sm font-medium text-gray-700">
                                                                    Ignore updates agents
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.ignoreAgentChangedAgent = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.ignoreAgentChangedAgent ? this.state.enterprise.ignoreAgentChangedAgent : { id: 1, name: 'No' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="ignore-update-agents"
                                                                    id="ignore-update-agents"
                                                                />
                                                            </div>
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="ignore-new-badge" className="block text-sm font-medium text-gray-700">
                                                                    Ignore newly listed badge
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.ignoreBadgeJustListed = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.ignoreBadgeJustListed ? this.state.enterprise.ignoreBadgeJustListed : { id: 2, name: 'Yes' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="ignore-new-badge"
                                                                    id="ignore-new-badge"
                                                                />
                                                            </div>
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="badge-countdown" className="block text-sm font-medium text-gray-700">
                                                                    Badge countdown<span className="ml-1 text-xs">(minutes)</span>
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.badgeCountDown}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.badgeCountDown = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                    name="badge-countdown"
                                                                    id="badge-countdown"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="geo-lookup" className="block text-sm font-medium text-gray-700">
                                                                    Geo API lookup
                                                                    <span className="ml-1 text-xs">(override datasource)</span>
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.geoCodeParser = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.geoCodeParser ? this.state.enterprise.geoCodeParser : { id: 0, name: 'Disabled' }}
                                                                    options={[
                                                                        {
                                                                            id: 0,
                                                                            value: "disabled",
                                                                            name: "Disabled"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: "enabled",
                                                                            name: "Enabled"
                                                                        }
                                                                    ]}
                                                                    name="geo-lookup"
                                                                    id="geo-lookup"
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>

                                                    <fieldset className="col-span-6">
                                                        <legend className="text-base font-medium text-gray-900">Sync filter</legend>
                                                        <div className="grid grid-cols-10 gap-6 mt-4">
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="min-price-sale" className="block text-sm font-medium text-gray-700">
                                                                    Min price Sale
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.minPriceSale}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.minPriceSale = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                    name="min-price-sale"
                                                                    id="min-price-sale"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="max-price-sale" className="block text-sm font-medium text-gray-700">
                                                                    Max price Sale
                                                                </label>
                                                                <input
                                                                    value={this.state.enterprise.maxPriceSale}
                                                                    onChange={(event) => {
                                                                        this.state.enterprise.maxPriceSale = event.target.value;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                    name="max-price-sale"
                                                                    id="max-price-sale"
                                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                            {
                                                                !(this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="min-price-rent" className="block text-sm font-medium text-gray-700">
                                                                        Min price Rent<span className="ml-1 text-xs">(yearly only)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.minPriceRent}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.minPriceRent = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="min-price-rent"
                                                                        id="min-price-rent"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="max-price-rent" className="block text-sm font-medium text-gray-700">
                                                                        Max price Rent<span className="ml-1 text-xs">(yearly only)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.maxPriceRent}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.maxPriceRent = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="max-price-rent"
                                                                        id="max-price-rent"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="grid grid-cols-10 gap-6 mt-6">
                                                            <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                <label htmlFor="custom-filter" className="block text-sm font-medium text-gray-900">
                                                                    Custom filter
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.customFilter = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.customFilter ? this.state.enterprise.customFilter : { id: 1, value: false, name: "Disable" }}
                                                                        options={[
                                                                            { id: 0, value: true, name: "Enable" },
                                                                            { id: 1, value: false, name: "Disable" }
                                                                        ]}
                                                                        name="custom-filter"
                                                                        id="custom-filter"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.enterprise.customFilter && this.state.enterprise.customFilter.value &&
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="custom-filter-include" className="block text-sm font-medium text-gray-900">
                                                                        Custom filter: Include/Exclude
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.customFilterInclude = option;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.customFilterInclude ? this.state.enterprise.customFilterInclude : { id: 0, value: true, name: "Include" }}
                                                                            options={[
                                                                                { id: 0, value: true, name: "Include" },
                                                                                { id: 1, value: false, name: "Exclude" }
                                                                            ]}
                                                                            name="ustom-filter-include"
                                                                            id="ustom-filter-include"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise.customFilter && this.state.enterprise.customFilter.value &&
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="custom-filter-key" className="block text-sm font-medium text-gray-900">
                                                                        Custom filter: Key
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <input
                                                                            onChange={(event) => {
                                                                                this.state.enterprise.customFilterKey = event.target.value;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            value={this.state.enterprise.customFilterKey ? this.state.enterprise.customFilterKey : ""}
                                                                            type="text"
                                                                            name="custom-filter-key"
                                                                            id="custom-filter-key"
                                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise.customFilter && this.state.enterprise.customFilter.value &&
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="custom-filter-empty" className="block text-sm font-medium text-gray-900">
                                                                        Custom filter: Empty value
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.state.enterprise.customFilterEmpty = option;
                                                                                if (option.value) {
                                                                                    this.state.enterprise.customFilterValue = "";
                                                                                }
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            selected={this.state.enterprise.customFilterEmpty ? this.state.enterprise.customFilterEmpty : { id: 1, value: false, name: "False" }}
                                                                            options={[
                                                                                { id: 0, value: true, name: "True" },
                                                                                { id: 1, value: false, name: "False" }
                                                                            ]}
                                                                            name="custom-filter-empty"
                                                                            id="custom-filter-empty"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.enterprise.customFilter && this.state.enterprise.customFilter.value &&
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    {
                                                                        this.state.enterprise.customFilterEmpty && this.state.enterprise.customFilterEmpty.value &&
                                                                        <div className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white bg-opacity-50"></div>
                                                                    }
                                                                    <label htmlFor="custom-filter-value" className="block text-sm font-medium text-gray-900">
                                                                        Custom filter: Value
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <input
                                                                            onChange={(event) => {
                                                                                this.state.enterprise.customFilterValue = event.target.value;
                                                                                this.setState({
                                                                                    enterprise: this.state.enterprise
                                                                                })
                                                                            }}
                                                                            value={this.state.enterprise.customFilterValue ? this.state.enterprise.customFilterValue : ""}
                                                                            type="text"
                                                                            name="custom-filter-value"
                                                                            id="custom-filter-value"
                                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </fieldset>

                                                    <fieldset className="col-span-6">
                                                        <legend className="text-base font-medium text-gray-900">Custom themes</legend>
                                                        {
                                                            !(this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) &&
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                {
                                                                    [
                                                                        { title: "Property in focus", value: 'customThemePropertyInFocus' },
                                                                        { title: "Agency in focus", value: 'customThemeAgencyInFocus' },
                                                                        { title: "Broker in focus", value: 'customThemeBrokerInFocus' },
                                                                        { title: "Promo in focus", value: 'customThemePromoInFocus' },
                                                                        { title: "Basic in focus", value: 'customThemeBasicInFocus' }
                                                                    ].map((item) => {
                                                                        return (
                                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                                <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                                                                                    {item.title}
                                                                                </label>
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.enterprise[item.value] = option;
                                                                                        this.setState({
                                                                                            enterprise: this.state.enterprise
                                                                                        })
                                                                                    }}
                                                                                    selected={this.state.enterprise[item.value] ? this.state.enterprise[item.value] : { id: 0, name: 'Disabled' }}
                                                                                    options={[
                                                                                        {
                                                                                            id: 0,
                                                                                            value: "disabled",
                                                                                            name: "Disabled"
                                                                                        },
                                                                                        {
                                                                                            id: 2,
                                                                                            value: "enabled",
                                                                                            name: "Enabled"
                                                                                        }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1 &&
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                {
                                                                    [
                                                                        { title: "Foundation", value: 'customThemeFoundation' },
                                                                        { title: "Essence", value: 'customThemeEssence' },
                                                                        { title: "Tonal", value: 'customThemeTonal' }
                                                                    ].map((item) => {
                                                                        return (
                                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                                <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                                                                                    {item.title}
                                                                                </label>
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.enterprise[item.value] = option;
                                                                                        this.setState({
                                                                                            enterprise: this.state.enterprise
                                                                                        })
                                                                                    }}
                                                                                    selected={this.state.enterprise[item.value] ? this.state.enterprise[item.value] : { id: 0, name: 'Disabled' }}
                                                                                    options={[
                                                                                        {
                                                                                            id: 0,
                                                                                            value: "disabled",
                                                                                            name: "Disabled"
                                                                                        },
                                                                                        {
                                                                                            id: 2,
                                                                                            value: "enabled",
                                                                                            name: "Enabled"
                                                                                        }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </fieldset>

                                                    <fieldset className="col-span-6">
                                                        <legend className="text-base font-medium text-gray-900">Ad creatives</legend>
                                                        <div className="grid grid-cols-6 gap-6  mt-4">
                                                            {
                                                                !(this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="fallback-theme" className="block text-sm font-medium text-gray-700">
                                                                        Fallback theme
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.feedImageCreative = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.feedImageCreative ? this.state.enterprise.feedImageCreative : { id: 0, name: 'Select creative' }}
                                                                        options={[
                                                                            {
                                                                                id: 1,
                                                                                value: "property_focus",
                                                                                name: "Property in focus"
                                                                            },
                                                                            {
                                                                                id: 2,
                                                                                value: "agency_focus",
                                                                                name: "Agency in focus"
                                                                            },
                                                                            {
                                                                                id: 3,
                                                                                value: "broker_focus",
                                                                                name: "Agent in focus"
                                                                            },
                                                                            {
                                                                                id: 4,
                                                                                value: "promo_focus",
                                                                                name: "Promo in focus"
                                                                            },
                                                                            {
                                                                                id: 5,
                                                                                value: "basic_focus",
                                                                                name: "Basic"
                                                                            }
                                                                        ]}
                                                                        name="fallback-theme"
                                                                        id="fallback-theme"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="badge" className="block text-sm font-medium text-gray-700">
                                                                        Badge
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.feedImageSlogan = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.feedImageSlogan ? this.state.enterprise.feedImageSlogan : { id: 0, name: 'Auto-badge' }}
                                                                        options={[
                                                                            {
                                                                                id: 0,
                                                                                name: "Auto-badge"
                                                                            },
                                                                            {
                                                                                id: 1,
                                                                                name: "Verified Listing"
                                                                            },
                                                                            {
                                                                                id: 2,
                                                                                name: "Genuine Listing"
                                                                            },
                                                                            {
                                                                                id: 3,
                                                                                name: "True Listing"
                                                                            }
                                                                        ]}
                                                                        name="badge"
                                                                        id="badge"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="cta" className="block text-sm font-medium text-gray-700">
                                                                        Call-to-action
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.callToActionSlogan = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.callToActionSlogan ? this.state.enterprise.callToActionSlogan : { id: 0, name: 'Learn More' }}
                                                                        options={[
                                                                            {
                                                                                id: 0,
                                                                                name: "Learn More"
                                                                            },
                                                                            {
                                                                                id: 1,
                                                                                name: "Call Now!"
                                                                            },
                                                                            {
                                                                                id: 2,
                                                                                name: "Book Viewing"
                                                                            },
                                                                            {
                                                                                id: 3,
                                                                                name: "Reserve It"
                                                                            },
                                                                            {
                                                                                id: 4,
                                                                                name: "Enquire Now"
                                                                            }
                                                                        ]}
                                                                        name="cta"
                                                                        id="cta"
                                                                    />
                                                                </div>
                                                            }
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="permit-number" className="block text-sm font-medium text-gray-700">
                                                                    Add permit number<span className="ml-1 text-xs">(if exist)</span>
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.setPermitNumberCreative = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.setPermitNumberCreative ? this.state.enterprise.setPermitNumberCreative : { id: 2, name: 'No' }}
                                                                    options={[
                                                                        { id: 1, name: 'Yes' },
                                                                        { id: 2, name: 'No' }
                                                                    ]}
                                                                    name="permit-number"
                                                                    id="permit-number"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="qr-code" className="block text-sm font-medium text-gray-700">
                                                                    Add QR code<span className="ml-1 text-xs">(if exist)</span>
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.setQrCodeCreative = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.setQrCodeCreative ? this.state.enterprise.setQrCodeCreative : { id: 2, name: 'No' }}
                                                                    options={[
                                                                        { id: 1, name: 'Yes' },
                                                                        { id: 2, name: 'No' }
                                                                    ]}
                                                                    name="qr-code"
                                                                    id="qr-code"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="info-box-status" className="block text-sm font-medium text-gray-700">
                                                                    Disable info box
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.disableInfoboxCreative = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.disableInfoboxCreative ? this.state.enterprise.disableInfoboxCreative : { id: 1, name: 'No' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="info-box-status"
                                                                    id="info-box-status"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="opacity-info-box" className="block text-sm font-medium text-gray-700">
                                                                    Opacity info box
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.opacityInfoboxCreative = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.opacityInfoboxCreative ? this.state.enterprise.opacityInfoboxCreative : { id: 1, name: 'No' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="opacity-info-box"
                                                                    id="opacity-info-box"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="info-box-size" className="block text-sm font-medium text-gray-700">
                                                                    Info box size (m)
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.infoboxMCreative = option;
                                                                        if (this.state.enterprise.infoboxSCreative && this.state.enterprise.infoboxSCreative.value) {
                                                                            this.state.enterprise.infoboxSCreative = { id: 1, value: false, name: 'No' }
                                                                        }
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.infoboxMCreative ? this.state.enterprise.infoboxMCreative : { id: 1, name: 'No' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="info-box-size"
                                                                    id="info-box-size"
                                                                />
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="info-box-size" className="block text-sm font-medium text-gray-700">
                                                                    Info box size (s)
                                                                </label>
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.enterprise.infoboxSCreative = option;
                                                                        if (this.state.enterprise.infoboxMCreative && this.state.enterprise.infoboxMCreative.value) {
                                                                            this.state.enterprise.infoboxMCreative = { id: 1, value: false, name: 'No' }
                                                                        }
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.infoboxSCreative ? this.state.enterprise.infoboxSCreative : { id: 1, name: 'No' }}
                                                                    options={[
                                                                        {
                                                                            id: 1,
                                                                            value: false,
                                                                            name: "No"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: true,
                                                                            name: "Yes"
                                                                        }
                                                                    ]}
                                                                    name="info-box-size"
                                                                    id="info-box-size"
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    {
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">Landing creatives</legend>
                                                            <div className="grid grid-cols-6 gap-6  mt-4">
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="landing-theme" className="block text-sm font-medium text-gray-700">
                                                                        Theme
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.landingPageCreativeTheme = option;
                                                                            this.state.enterprise.landingPageCustomTheme = { id: 0, name: 'Disabled' };
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.landingPageCreativeTheme ? this.state.enterprise.landingPageCreativeTheme : { id: 0, name: 'Select theme' }}
                                                                        options={[
                                                                            {
                                                                                id: 1,
                                                                                value: "property_focus",
                                                                                custom: 'customThemePropertyInFocus',
                                                                                name: "Property in focus"
                                                                            },
                                                                            {
                                                                                id: 2,
                                                                                value: "agency_focus",
                                                                                custom: 'customThemeAgencyInFocus',
                                                                                name: "Agency in focus"
                                                                            },
                                                                            {
                                                                                id: 3,
                                                                                value: "broker_focus",
                                                                                custom: 'customThemeBrokerInFocus',
                                                                                name: "Agent in focus"
                                                                            },
                                                                            {
                                                                                id: 4,
                                                                                value: "promo_focus",
                                                                                custom: 'customThemePromoInFocus',
                                                                                name: "Promo in focus"
                                                                            },
                                                                            {
                                                                                id: 5,
                                                                                value: "basic_focus",
                                                                                custom: 'customThemeBasicInFocus',
                                                                                name: "Basic"
                                                                            }
                                                                        ]}
                                                                        name="landing-theme"
                                                                        id="landing-theme"
                                                                    />
                                                                </div>
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="custom-theme-status" className="block text-sm font-medium text-gray-700">
                                                                        Custom theme
                                                                    </label>
                                                                    <SingleSelection
                                                                        disabled={!(this.state.enterprise.landingPageCreativeTheme && this.state.enterprise[this.state.enterprise.landingPageCreativeTheme.custom] && this.state.enterprise[this.state.enterprise.landingPageCreativeTheme.custom].value === 'enabled')}
                                                                        select={(option) => {
                                                                            this.state.enterprise.landingPageCustomTheme = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.landingPageCustomTheme ? this.state.enterprise.landingPageCustomTheme : { id: 0, name: 'Disabled' }}
                                                                        options={[
                                                                            {
                                                                                id: 0,
                                                                                value: "disabled",
                                                                                name: "Disabled"
                                                                            },
                                                                            {
                                                                                id: 1,
                                                                                value: "enabled",
                                                                                name: "Enabled"
                                                                            }
                                                                        ]}
                                                                        name="custom-theme-status"
                                                                        id="custom-theme-status"
                                                                    />
                                                                </div>
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="landing-creative-lang" className="block text-sm font-medium text-gray-700">
                                                                        Language
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.landingPageCreativeLanguage = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.landingPageCreativeLanguage ? this.state.enterprise.landingPageCreativeLanguage : { id: 0, name: 'Select language' }}
                                                                        options={[
                                                                            { id: 1, name: 'English', value: "en" },
                                                                            { id: 2, name: 'Swedish', value: "sv" },
                                                                            { id: 3, name: 'Arabic', value: "ar" },
                                                                            { id: 4, name: 'German', value: "de" },
                                                                            { id: 5, name: 'Spanish', value: "es" },
                                                                            { id: 6, name: 'French', value: "fr" },
                                                                            { id: 7, name: 'Russian', value: "ru" },
                                                                            { id: 8, name: 'Dutch', value: "nl" },
                                                                            { id: 9, name: 'Portuguese', value: "pt" },
                                                                            { id: 10, name: 'Italian', value: "it" },
                                                                        ]}
                                                                        name="landing-creative-lang"
                                                                        id="landing-creative-lang"
                                                                    />
                                                                </div>
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="landing-creative-currency" className="block text-sm font-medium text-gray-700">
                                                                        Currency
                                                                    </label>
                                                                    <SingleSelection
                                                                        noLimit={true}
                                                                        select={(option) => {
                                                                            this.state.enterprise.landingPageCreativeCurrency = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.landingPageCreativeCurrency ? this.state.enterprise.landingPageCreativeCurrency : { id: 0, name: 'Select currency' }}
                                                                        options={[
                                                                            { id: 1, name: 'USD', value: "usd" },
                                                                            { id: 2, name: 'SEK', value: "sek" },
                                                                            { id: 3, name: 'AED', value: "aed" },
                                                                            { id: 4, name: 'EUR', value: "eur" },
                                                                            { id: 5, name: 'GBP', value: "gbp" },
                                                                            { id: 6, name: 'ILS', value: "ils" },
                                                                            { id: 7, name: 'AUD', value: "aud" },
                                                                            { id: 8, name: 'CHF', value: "chf" },
                                                                            { id: 9, name: 'ZAR', value: "zar" },
                                                                            { id: 10, name: 'NZD', value: "nzd" },
                                                                            { id: 11, name: 'INR', value: "inr" },
                                                                            { id: 12, name: 'CAD', value: "cad" },
                                                                            { id: 13, name: 'NOK', value: "nok" },
                                                                            { id: 14, name: 'DKK', value: "dkk" },
                                                                            { id: 15, name: 'SAR', value: "sar" },
                                                                            { id: 16, name: 'QAR', value: "qar" }
                                                                        ]}
                                                                        name="landing-creative-currency"
                                                                        id="landing-creative-currency"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }
                                                    {
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">Landing settings</legend>
                                                            <div className="grid grid-cols-6 gap-6 mt-4">
                                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                    <label htmlFor="listing-description" className="block text-sm font-medium text-gray-700">
                                                                        Send listing description
                                                                    </label>
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.enterprise.sendDescriptionLanding = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise.sendDescriptionLanding ? this.state.enterprise.sendDescriptionLanding : { id: 1, name: 'Disabled', value: false }}
                                                                        options={[
                                                                            { id: 1, name: 'Disabled', value: "disabled" },
                                                                            { id: 2, name: 'Enabled', value: "enabled" }
                                                                        ]}
                                                                        name="listing-description"
                                                                        id="listing-description"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }
                                                    {
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">Landing plugins</legend>
                                                            <div className="grid grid-cols-6 gap-6  mt-4">
                                                                {
                                                                    this.state.adbooster_plugins.map((item) => {
                                                                        return (
                                                                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                                                <div onClick={() => {
                                                                                    if (!this.state.enterprise.plugins) {
                                                                                        this.state.enterprise.plugins = {};
                                                                                        this.state.enterprise.plugins[item.id] = item;
                                                                                        if (item.id === 9) {
                                                                                            this.state.enterprise.plugins[8] = { id: 8, name: "Image block" };
                                                                                        }
                                                                                        if (item.id === 2) {
                                                                                            delete this.state.enterprise.plugins[3];
                                                                                        }
                                                                                        if (item.id === 3) {
                                                                                            delete this.state.enterprise.plugins[2];
                                                                                        }
                                                                                        if (item.id === 4) {
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 5) {
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 6) {
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 7) {
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                        }
                                                                                    } else if (this.state.enterprise.plugins && this.state.enterprise.plugins[item.id]) {
                                                                                        delete this.state.enterprise.plugins[item.id];
                                                                                        if (item.id === 8) {
                                                                                            delete this.state.enterprise.plugins[9];
                                                                                        }
                                                                                        if (item.id === 2) {
                                                                                            delete this.state.enterprise.plugins[3];
                                                                                        }
                                                                                        if (item.id === 3) {
                                                                                            delete this.state.enterprise.plugins[2];
                                                                                        }
                                                                                        if (item.id === 4) {
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 5) {
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 6) {
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 7) {
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                        }
                                                                                    } else {
                                                                                        this.state.enterprise.plugins[item.id] = item;
                                                                                        if (item.id === 9) {
                                                                                            this.state.enterprise.plugins[8] = { id: 8, name: "Image block" };
                                                                                        }
                                                                                        if (item.id === 2) {
                                                                                            delete this.state.enterprise.plugins[3];
                                                                                        }
                                                                                        if (item.id === 3) {
                                                                                            delete this.state.enterprise.plugins[2];
                                                                                        }
                                                                                        if (item.id === 4) {
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 5) {
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 6) {
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[7];
                                                                                        }
                                                                                        if (item.id === 7) {
                                                                                            delete this.state.enterprise.plugins[4];
                                                                                            delete this.state.enterprise.plugins[5];
                                                                                            delete this.state.enterprise.plugins[6];
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        enterprise: this.state.enterprise
                                                                                    })
                                                                                }} className={(this.state.enterprise.plugins && this.state.enterprise.plugins[item.id] ? "border-indigo-500 text-indigo-500" : "border-gray-300 text-gray-900") + "  pl-3 border shadow-sm rounded-md py-2 px-2 flex flex-row cursor-pointer"}>
                                                                                    <div className="flex flex-1 flex-col text-sm">
                                                                                        <p className="">{item.name}</p>
                                                                                    </div>
                                                                                    {
                                                                                        this.state.enterprise.plugins && this.state.enterprise.plugins[item.id] &&
                                                                                        <div>
                                                                                            <CheckIcon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </fieldset>
                                                    }
                                                    {
                                                        !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                        <fieldset className="col-span-6">
                                                            <legend className="text-base font-medium text-gray-900">Plugin filter</legend>
                                                            <div className="grid grid-cols-10 gap-6 mt-4">
                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="min-price-prequalify-sale" className="block text-sm font-medium text-gray-700">
                                                                        Min price Pre-qualify<span className="ml-1 text-xs">(sale)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.minPricePluginPrequalifySale ? this.state.enterprise.minPricePluginPrequalifySale : 0}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.minPricePluginPrequalifySale = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="min-price-prequalify-sale"
                                                                        id="min-price-prequalify-sale"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>

                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="min-price-prequalify-rent" className="block text-sm font-medium text-gray-700">
                                                                        Min price Pre-qualify<span className="ml-1 text-xs">(rent yearly)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.minPricePluginPrequalifyRent ? this.state.enterprise.minPricePluginPrequalifyRent : 0}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.minPricePluginPrequalifyRent = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="min-price-prequalify-rent"
                                                                        id="min-price-prequalify-rent"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>

                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="min-price-signature-sale" className="block text-sm font-medium text-gray-700">
                                                                        Min price Signature services<span className="ml-1 text-xs">(sale)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.minPricePluginExtraServicesSale ? this.state.enterprise.minPricePluginExtraServicesSale : 0}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.minPricePluginExtraServicesSale = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="min-price-signature-sale"
                                                                        id="min-price-signature-sale"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>

                                                                <div className="col-span-10 sm:col-span-5 lg:col-span-2">
                                                                    <label htmlFor="min-price-signature-rent" className="block text-sm font-medium text-gray-700">
                                                                        Min price Signature services<span className="ml-1 text-xs">(rent yearly)</span>
                                                                    </label>
                                                                    <input
                                                                        value={this.state.enterprise.minPricePluginExtraServicesRent ? this.state.enterprise.minPricePluginExtraServicesRent : 0}
                                                                        onChange={(event) => {
                                                                            this.state.enterprise.minPricePluginExtraServicesRent = event.target.value;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        type="number"
                                                                        name="min-price-signature-rent"
                                                                        id="min-price-signature-rent"
                                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    }
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex flex-row">
                                                {
                                                    !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.loading_adboosterleads) {
                                                                this.functions.syncAdboosterLeads();
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="bg-indigo-600 mr-2 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        {
                                                            this.state.loading_adboosterleads &&
                                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Sync leads
                                                    </button>
                                                }
                                                {
                                                    !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                    <button
                                                        onClick={() => {
                                                            if (!this.state.loading_removeleads) {
                                                                this.functions.removeAllLeads();
                                                            }
                                                        }}
                                                        type="submit"
                                                        className="bg-red-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        {
                                                            this.state.loading_removeleads &&
                                                            <div className="w-full h-full absolute bg-red-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        Remove leads
                                                    </button>
                                                }
                                                <div className="flex flex-1"></div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading) {
                                                            this.setState({
                                                                loading_update: true,
                                                                form_four: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update && this.state.form_four &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*UI manager*/}
                                {
                                    this.state.tab == 13 &&
                                    <div className="mb-8">
                                        <div className="shadow bg-white rounded-md">
                                            <div className=" py-6 px-4 space-y-6 sm:p-6">
                                                <div className="flex items-center">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">UI manager</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Set what should be visible in enterprise UI</p>
                                                    </div>
                                                </div>
                                                <legend className="text-base font-medium text-gray-900">Tabs</legend>
                                                <div className="grid grid-cols-12 gap-6">
                                                    {
                                                        [
                                                            { title: 'Dashboard', value: 'managerUIdashboard' },
                                                            { title: 'Listings', value: 'managerUIlistings' },
                                                            { title: 'Agents', value: 'managerUIagents' },
                                                            { title: 'Feeds', value: 'managerUIfeeds', light: true },
                                                            //{ title: 'Reports', value: 'managerUIreports' },
                                                            { title: 'Audience', value: 'managerUIaudience' },
                                                            { title: 'Clients', value: 'managerUIclients' },
                                                            { title: 'Proptree', value: 'managerUIproptree' },
                                                            { title: 'Leads', value: 'managerUIleads' },
                                                            { title: 'Report', value: 'managerUIreport' },
                                                            { title: 'Forms', value: 'managerUIforms', light: true },
                                                            { title: 'Opportunities', value: 'managerUIopportunities', legacy: true },
                                                            { title: 'Logs', value: 'managerUIlogs', light: true }
                                                        ].map((item) => {
                                                            return (
                                                                <div className="col-span-6 sm:col-span-2">
                                                                    <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                                                                        {item.title}
                                                                        {item.legacy && <span className="ml-1 text-xs">(legacy)</span>}
                                                                        {item.light && <span className="ml-1 text-xs">(light)</span>}
                                                                    </label>
                                                                    <SingleSelection
                                                                        noLimit={true}
                                                                        select={(option) => {
                                                                            this.state.enterprise[item.value] = option;
                                                                            this.setState({
                                                                                enterprise: this.state.enterprise
                                                                            })
                                                                        }}
                                                                        selected={this.state.enterprise[item.value] ? this.state.enterprise[item.value] : { id: 1, name: 'Enabled', value: 'enabled' }}
                                                                        options={[
                                                                            { id: 1, name: 'Enabled', value: 'enabled' },
                                                                            { id: 2, name: 'Disabled', value: 'disabled' }
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                    <legend className="text-base font-medium text-gray-900">Limits</legend>
                                                }
                                                {
                                                    this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                    <div className="grid grid-cols-12 gap-6">
                                                        <div className="col-span-12 sm:col-span-3">
                                                            <label htmlFor="manager-create-users" className="block text-sm font-medium text-gray-700">
                                                                Allow manager to create users
                                                            </label>
                                                            <SingleSelection
                                                                noLimit={true}
                                                                select={(option) => {
                                                                    this.state.enterprise.managerUIcreateUsers = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.managerUIcreateUsers ? this.state.enterprise.managerUIcreateUsers : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="manager-create-users"
                                                                id="manager-create-users"
                                                            />
                                                        </div>
                                                        <div className="col-span-12 sm:col-span-3">
                                                            <label htmlFor="manager-create-feeds" className="block text-sm font-medium text-gray-700">
                                                                Allow manager to create feeds
                                                            </label>
                                                            <SingleSelection
                                                                noLimit={true}
                                                                select={(option) => {
                                                                    this.state.enterprise.managerUIcreateFeeds = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.managerUIcreateFeeds ? this.state.enterprise.managerUIcreateFeeds : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="manager-create-feeds"
                                                                id="manager-create-feeds"
                                                            />
                                                        </div>
                                                        <div className="col-span-12 sm:col-span-3">
                                                            <label htmlFor="limit-feeds" className="block text-sm font-medium text-gray-700">
                                                                Limit feeds to
                                                            </label>
                                                            <SingleSelection
                                                                noLimit={true}
                                                                select={(option) => {
                                                                    this.state.enterprise.limitFeedsAmount = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.limitFeedsAmount ? this.state.enterprise.limitFeedsAmount : { id: 1, name: '1', value: 1 }}
                                                                options={[
                                                                    { id: 1, name: '1', value: 1 },
                                                                    { id: 2, name: '2', value: 2 },
                                                                    { id: 3, name: '3', value: 3 },
                                                                    { id: 4, name: '4', value: 4 },
                                                                    { id: 5, name: '5', value: 5 },
                                                                    { id: 6, name: '6', value: 6 },
                                                                    { id: 7, name: '7', value: 7 },
                                                                    { id: 8, name: '8', value: 8 },
                                                                    { id: 9, name: '9', value: 9 },
                                                                    { id: 10, name: '10', value: 10 },
                                                                    { id: 11, name: '11', value: 11 },
                                                                    { id: 12, name: '12', value: 12 },
                                                                    { id: 13, name: '13', value: 13 },
                                                                    { id: 14, name: '14', value: 14 },
                                                                    { id: 15, name: '15', value: 15 },
                                                                    { id: 16, name: '16', value: 16 },
                                                                    { id: 17, name: '17', value: 17 },
                                                                    { id: 18, name: '18', value: 18 },
                                                                    { id: 19, name: '19', value: 19 },
                                                                    { id: 20, name: '20', value: 20 },
                                                                ]}
                                                                name="limit-feeds"
                                                                id="limit-feeds"
                                                            />
                                                        </div>

                                                        <div className="col-span-12 sm:col-span-3">
                                                            <label htmlFor="limit-feeds" className="block text-sm font-medium text-gray-700">
                                                                Limit Proptree to
                                                            </label>
                                                            <SingleSelection
                                                                noLimit={true}
                                                                select={(option) => {
                                                                    this.state.enterprise.limitProptreeAmount = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.limitProptreeAmount ? this.state.enterprise.limitProptreeAmount : { id: 0, name: 'Select limit', value: 0 }}
                                                                options={[
                                                                    { id: 1, name: '1', value: 1 },
                                                                    { id: 2, name: '2', value: 2 },
                                                                    { id: 3, name: '3', value: 3 },
                                                                    { id: 4, name: '4', value: 4 },
                                                                    { id: 5, name: '5', value: 5 },
                                                                    { id: 6, name: '6', value: 6 },
                                                                    { id: 7, name: '7', value: 7 },
                                                                    { id: 8, name: '8', value: 8 },
                                                                    { id: 9, name: '9', value: 9 },
                                                                    { id: 10, name: '10', value: 10 },
                                                                    { id: 11, name: '11', value: 11 },
                                                                    { id: 12, name: '12', value: 12 },
                                                                    { id: 13, name: '13', value: 13 },
                                                                    { id: 14, name: '14', value: 14 },
                                                                    { id: 15, name: '15', value: 15 },
                                                                    { id: 16, name: '16', value: 16 },
                                                                    { id: 17, name: '17', value: 17 },
                                                                    { id: 18, name: '18', value: 18 },
                                                                    { id: 19, name: '19', value: 19 },
                                                                    { id: 20, name: '20', value: 20 },
                                                                ]}
                                                                name="limit-feeds"
                                                                id="limit-feeds"
                                                            />
                                                        </div>

                                                        <div className="col-span-12 sm:col-span-3">
                                                            <label htmlFor="manager-create-forms" className="block text-sm font-medium text-gray-700">
                                                                Allow manager to create forms
                                                            </label>
                                                            <SingleSelection
                                                                noLimit={true}
                                                                select={(option) => {
                                                                    this.state.enterprise.managerUIcreateForms = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.managerUIcreateForms ? this.state.enterprise.managerUIcreateForms : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="manager-create-forms"
                                                                id="manager-create-forms"
                                                            />
                                                        </div>
                                                        {
                                                            this.state.enterprise && this.state.enterprise.managerUIcreateForms && this.state.enterprise.managerUIcreateForms.value === "enabled" &&
                                                            <div className="col-span-12 sm:col-span-3">
                                                                <label htmlFor="manager-create-questions" className="block text-sm font-medium text-gray-700">
                                                                    Allow manager to create questions
                                                                </label>
                                                                <SingleSelection
                                                                    noLimit={true}
                                                                    select={(option) => {
                                                                        this.state.enterprise.managerUIcreateQuestions = option;
                                                                        this.setState({
                                                                            enterprise: this.state.enterprise
                                                                        })
                                                                    }}
                                                                    selected={this.state.enterprise.managerUIcreateQuestions ? this.state.enterprise.managerUIcreateQuestions : { id: 1, name: 'Enabled', value: 'enabled' }}
                                                                    options={[
                                                                        { id: 1, name: 'Enabled', value: 'enabled' },
                                                                        { id: 2, name: 'Disabled', value: 'disabled' }
                                                                    ]}
                                                                    name="manager-create-forms"
                                                                    id="manager-create-forms"
                                                                />
                                                            </div>
                                                        }
                                                        <div className="col-span-12 sm:col-span-3">
                                                            <label htmlFor="manager-remove-clients" className="block text-sm font-medium text-gray-700">
                                                                Allow manager to remove clients
                                                            </label>
                                                            <SingleSelection
                                                                noLimit={true}
                                                                select={(option) => {
                                                                    this.state.enterprise.managerUIremoveClient = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.managerUIremoveClient ? this.state.enterprise.managerUIremoveClient : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="manager-remove-clients"
                                                                id="manager-remove-clients"
                                                            />
                                                        </div>
                                                        <div className="col-span-12 sm:col-span-3">
                                                            <label htmlFor="manager-remove-leads" className="block text-sm font-medium text-gray-700">
                                                                Allow manager to remove leads
                                                            </label>
                                                            <SingleSelection
                                                                noLimit={true}
                                                                select={(option) => {
                                                                    this.state.enterprise.managerUIremoveLead = option;
                                                                    this.setState({
                                                                        enterprise: this.state.enterprise
                                                                    })
                                                                }}
                                                                selected={this.state.enterprise.managerUIremoveLead ? this.state.enterprise.managerUIremoveLead : { id: 2, name: 'Disabled', value: 'disabled' }}
                                                                options={[
                                                                    { id: 1, name: 'Enabled', value: 'enabled' },
                                                                    { id: 2, name: 'Disabled', value: 'disabled' }
                                                                ]}
                                                                name="manager-remove-leads"
                                                                id="manager-remove-leads"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 rounded-b-md">
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading) {
                                                            this.setState({
                                                                loading_update: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CURRENCY*/}
                                {
                                    this.state.tab == 9 &&
                                    <div className="mb-8">
                                        <div className="shadow bg-white rounded-md">
                                            <div className=" py-6 px-4 space-y-6 sm:p-6">
                                                <div className="flex items-center">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Currency</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Latest update {moment(this.state.enterprise.currenciesUpdated).format("YYYY-MM-DD")}</p>
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                if (!this.state.loading_currencies) {
                                                                    this.setState({
                                                                        loading_currencies: true
                                                                    }, () => {
                                                                        this.functions.updateCurrencies();
                                                                    })
                                                                }
                                                            }}
                                                            type="button"
                                                            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        >
                                                            <RefreshIcon className="h-5 w-5" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-6 gap-6">
                                                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                                                        <label htmlFor="base-currency" className="block text-sm font-medium text-gray-700">
                                                            Base currency
                                                        </label>
                                                        <SingleSelection
                                                            noLimit={true}
                                                            select={(option) => {
                                                                this.state.enterprise.baseCurrency = option;
                                                                this.setState({
                                                                    enterprise: this.state.enterprise
                                                                })
                                                            }}
                                                            selected={this.state.enterprise.baseCurrency ? this.state.enterprise.baseCurrency : { id: 0, name: 'Select Currency' }}
                                                            options={[
                                                                { id: 1, name: 'USD', value: "usd" },
                                                                { id: 2, name: 'SEK', value: "sek" },
                                                                { id: 3, name: 'AED', value: "aed" },
                                                                { id: 4, name: 'EUR', value: "eur" },
                                                                { id: 5, name: 'GBP', value: "gbp" },
                                                                { id: 6, name: 'ILS', value: "ils" },
                                                                { id: 7, name: 'AUD', value: "aud" },
                                                                { id: 8, name: 'CHF', value: "chf" },
                                                                { id: 9, name: 'ZAR', value: "zar" },
                                                                { id: 10, name: 'NZD', value: "nzd" },
                                                                { id: 11, name: 'INR', value: "inr" },
                                                                { id: 12, name: 'CAD', value: "cad" },
                                                                { id: 13, name: 'NOK', value: "nok" },
                                                                { id: 14, name: 'DKK', value: "dkk" },
                                                                { id: 15, name: 'SAR', value: "sar" },
                                                                { id: 16, name: 'QAR', value: "qar" }
                                                            ]}
                                                            name="base-currency"
                                                            id="base-currency"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 rounded-b-md">
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading) {
                                                            this.setState({
                                                                loading_update: true
                                                            }, () => {
                                                                this.functions.updateEnterprise();
                                                            })
                                                        }
                                                    }}
                                                    type="submit"
                                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    {
                                                        this.state.loading_update &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*CSV FEED PARSED*/}
                                {
                                    this.state.tab == 9 &&
                                    this.state.enterprise.currencies && Object.keys(this.state.enterprise.currencies).length > 0 &&
                                    <div className="flex flex-col">
                                        <div className="w-full">
                                            <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                            >
                                                                Currency
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                            >
                                                                Value
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(this.state.enterprise.currencies).map((key, index) => (
                                                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{key}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{this.state.enterprise.currencies[key]}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </main>
                }

                {/*CREATE USER*/}
                {
                    !this.state.loading &&
                    <CreateUser
                        user={this.state.selected}
                        open={this.state.open}
                        agentOnly={true}
                        enterprise={{ id: this.state.enterprise.id, name: this.state.enterprise.name }}
                        toggle={() => {
                            this.setState({
                                open: !this.state.open
                            })
                        }}
                        onUser={(user) => {
                            if (!Array.isArray(this.state.enterprise.users)) {
                                this.state.enterprise.users = [];
                            }
                            this.state.enterprise.users.push(user);
                            this.setState({
                                enterprise: this.state.enterprise
                            })
                        }}
                        onUpdate={(user) => {
                            this.state.enterprise.users = this.state.enterprise.users.map((item) => {
                                if (item.id == user.id) {
                                    item = user;
                                }
                                return item;
                            });
                            this.setState({
                                enterprise: this.state.enterprise
                            })
                        }}
                    />
                }

                {/*CREATE FEED*/}
                {
                    !this.state.loading &&
                    <CreateFeed
                        version={this.state.enterprise.version}
                        enterpriseId={this.state.enterprise.id}
                        enterprise={this.state.enterprise}
                        enterpriseBaseLanguage={this.state.enterprise && this.state.enterprise.baseLanguage ? this.state.enterprise.baseLanguage : { id: 1, name: 'English', value: "en" }}
                        enterpriseBaseCurrency={this.state.enterprise && this.state.enterprise.baseCurrency ? this.state.enterprise.baseCurrency : { id: 1, name: 'USD', value: "usd" }}
                        open={this.state.open_create_feed}
                        toggle={() => {
                            this.setState({
                                open_create_feed: !this.state.open_create_feed
                            })
                        }}
                        onFeed={(feed) => {
                            if (!Array.isArray(this.state.enterprise.feeds)) {
                                this.state.enterprise.feeds = [];
                            }
                            this.state.enterprise.feeds.push(feed);
                            this.setState({
                                enterprise: this.state.enterprise
                            })
                        }}
                    />}

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="w-full h-64 relative flex justify-center items-start">
                        <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }

            </>
        )
    }
}

export default Enterprise;
